<template>
  <div class="contact-us">
    <v-container>
      <v-row justify="center">
        <v-col md="8" cols="11" style="z-index: 2">
          <h1 class="title-h1" data-aos="fade-down">{{ $t("0003") }}</h1>
          <strong class="strong">{{ $t("0047") }}</strong>

          <div data-aos="fade-up" data-aos-delay="500" class="content-box mt-8">
            <span>{{ $t("0048") }} :</span>
            Sriracha SAHA Group Industrial Park
          </div>

          <div data-aos="fade-up" data-aos-delay="800" class="content-box">
            <span>{{ $t("0049") }} :</span>
            October 17, 1988
          </div>

          <div data-aos="fade-up" data-aos-delay="1400" class="content-box">
            <span>{{ $t("0050") }} :</span>
            Samsung Electronics / Sahapat.
            <div class="map">
              <a target="blank" href="https://www.google.com/maps/place/Thai+Samsung+Electronics+Company+Limited,+Bueng,+Si+Racha+District,+Chon+Buri+20230/@13.095291,100.967628,17z/data=!4m5!3m4!1s0x3102b86dd1eb1b97:0xba8da2649713c6f2!8m2!3d13.0950223!4d100.9666577">
              <div class="filter-cover-map">
                <div class="direction-box"></div>
                <div class="txt-direction-box sharpsansbold">
                  click for open google map
                </div>
              </div></a>
              
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="triangle-top-left-6"></div>
    <div class="triangle-bottom-left-6"></div>
    <div class="triangle-right-bottom-5"></div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import api from "@/services/api";
export default {
  name: "ContactUs",
  created() {
    AOS.init();
  },
  async mounted(){
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const resultThaiSamsungCountView = await api.getThaiSamaungCountView();
        if (resultThaiSamsungCountView.status == 200) {
        }
      } catch (err) {
        console.log('err ' + err);
      } finally {
      }
    },
  },
};
</script>
<style scoped>
.contact-us {
  padding: 100px 0 120px 0;
}
.contact-us {
  width: auto;
}
.content-box {
  margin-bottom: 25px;
}
.content-box span {
  font-family: SAMSUNGSHARPSANSBOLD;
}
.map-samsung {
  width: -webkit-fill-available;
  margin-top: 10px;
}
.map {
  margin-top: 15px;
  width: 100%;
  height: 450px;
  background-image: url("../assets/images/map.png");
  position: relative;
  cursor: pointer;
  background-position: center;
  overflow: hidden;
}
.filter-cover-map {
  background-color: rgba(0, 0, 0,0) ;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.map:hover .filter-cover-map{
  background-color: rgba(0, 0, 0, 0.299);
}
.direction-box {
  width: 284px;
  height: 108px;
  background-image: url("../assets/images/direction-box.png");
  position: absolute;
  z-index: 2;
  border-radius: 2px;
  margin: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.13);
}
.txt-direction-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-size: 1.125rem;
  visibility: hidden;
  color: #fff;
  letter-spacing: 1.5px;
}
.map:hover .txt-direction-box {
  visibility: visible;
}
@media screen and (max-width: 767px) {
  .contact-us {
    padding: 60px 0 100px 0;
  }
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }
  .content-box {
    margin-bottom: 15px;
  }
}
</style>
