<template>
     <section id="section4" class="section-4">
      <div class="intro-wrap-4" style="z-index: 2">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div
                class="text-header-section"
                data-aos="fade-up"
                data-aos-delay="5"
              >
                What We Value
              </div>
              <div class="liner mt-2" data-aos="fade-up" data-aos-delay="20">
                {{ $t("0058") }}
              </div>
            </v-col>
            <v-col cols="12">
              <v-row justify="center" align="center">
                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  class="wrap-value-item"
                  data-aos="fade-up"
                  data-aos-delay="30"
                >
                  <div class="value-item">
                    <div class="wrap-ico ico-people">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_people.png')"
                      ></v-img>
                    </div>

                    <div class="value-title">{{ $t("0059") }}</div>
                    <div class="value-text">
                      {{ $t("0064") }}
                    </div>
                    <v-btn @click="$router.push({ name: 'AboutUs' }).catch(() => {})" class="mt-2" dark color="#2fbbf0" small
                      >read more</v-btn
                    >
                  </div>
                </v-col>

                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  class="wrap-value-item"
                  data-aos="fade-up"
                  data-aos-delay="20"
                >
                  <div class="value-item">
                    <div class="wrap-ico ico-excellence">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_excellance.png')"
                      ></v-img>
                    </div>
                    <div class="value-title">{{ $t("0060") }}</div>
                    <div class="value-text">
                      {{ $t("0065") }}
                    </div>
                    <v-btn @click="$router.push({ name: 'AboutUs' }).catch(() => {})" class="mt-2" dark color="#2fbbf0" small
                      >read more</v-btn
                    >
                  </div>
                </v-col>

                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  class="wrap-value-item"
                  data-aos="fade-up"
                  data-aos-delay="30"
                >
                  <div class="value-item">
                    <div class="wrap-ico ico-change">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_Change.png')"
                      ></v-img>
                    </div>
                    <div class="value-title">{{ $t("0061") }}</div>
                    <div class="value-text">
                      {{ $t("0066") }}
                    </div>
                    <v-btn @click="$router.push({ name: 'AboutUs' }).catch(() => {})" class="mt-2" dark color="#2fbbf0" small
                      >read more</v-btn
                    >
                  </div>
                </v-col>

                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  class="wrap-value-item"
                  data-aos="fade-up"
                  data-aos-delay="30"
                >
                  <div class="value-item">
                    <div class="wrap-ico ico-co-pro">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_Co_prosperity.png')"
                      ></v-img>
                    </div>
                    <div class="value-title">{{ $t("0062") }}</div>
                    <div class="value-text">
                      {{ $t("0067") }}
                    </div>
                    <v-btn @click="$router.push({ name: 'AboutUs' }).catch(() => {})" class="mt-2" dark color="#2fbbf0" small
                      >read more</v-btn
                    >
                  </div>
                </v-col>

                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  class="wrap-value-item"
                  data-aos="fade-up"
                  data-aos-delay="30"
                >
                  <div class="value-item">
                    <div class="wrap-ico ico-integrity">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_Intragity.png')"
                      ></v-img>
                    </div>
                    <div class="value-title">{{ $t("0063") }}</div>
                    <div class="value-text">
                      {{ $t("0068") }}
                    </div>
                    <v-btn @click="$router.push({ name: 'AboutUs' }).catch(() => {})" class="mt-2" dark color="#2fbbf0" small
                      >read more</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="triangle-right-top-3"></div>
      <div class="triangle-left-bottom-4"></div>
    </section>

</template>

<script>
export default {}
</script>

<style >
.intro-wrap-4 {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  max-width: 60%;
}
.wrap-value-item {
  padding-top: 40px;
}
.value-item {
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  text-align: center;
  transition: 0.7s;
  height: 200px;
  box-shadow: 0px 3px 5px -1px rgb(229 229 229 / 10%),
    0px 6px 10px 0px rgb(255 255 255 / 10%),
    0px 1px 18px 0px rgb(165 165 165 / 36%);
}
.wrap-ico {
  width: 90px;
  height: 90px;
  margin: 0 auto;
  border-radius: 50%;
  margin-top: -45px;
  position: relative;
}
.ico-people {
  background-color: #f2e0ff;
}
.ico-excellence {
  background-color: #b6e0ff;
}
.ico-change {
  background-color: #febbbc;
}
.ico-co-pro {
  background-color: #fccbaf;
}
.ico-integrity {
  background-color: #c0e7ca;
}
.value-ico {
  width: 100%;
  max-width: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
}
.value-title {
  font-family: SAMSUNGSHARPSANSBOLD;
  letter-spacing: 1px;
  margin-top: 10px;
  font-size: 1.125rem;
}
.value-text {
  padding: 5px 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  letter-spacing: 0.7px;
}
@media screen and (max-width: 1680px) {
  .intro-wrap-4 {
    max-width: 70%;
  }
  .value-item {
    height: 170px;
  }
  .wrap-ico {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 50%;
    margin-top: -45px;
    position: relative;
  }
  .value-ico {
    max-width: 50px;
  }
  .value-title {
    margin-top: 5px;
  }
  .value-text {
    padding: 0px 5px;
  }
}
@media screen and (max-width: 991px) {
  .section-4 {
    height: auto !important;
  }
  .intro-wrap-4 {
    max-width: 80% !important;
    position: relative;
    z-index: 1;
    top: unset !important;
    left: unset !important;
    transform: unset !important;

    
    margin: 0 auto;
    padding: 70px 0 70px 0;
  }
}
</style>
