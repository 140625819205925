import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }


const message = {
  th:{
    "0001": "หน้าหลัก",
    "0002": "เกี่ยวกับเรา",
    "0003": "ติดต่อเรา",
    "0004": "ร่วมงานกับเรา",
    "0005": "ผู้นำทางด้านเทคโนโลยี",
    "0006": "ไทย ซัมซุง อิเลคโทรนิคส์",
    "0007": "เราต้องการทำให้วัฒนธรรมองค์กรของเราเปิดกว้าง ยืดหยุ่น และสร้างสรรค์มากขึ้น",
    "0008": "Thai Samsung Electronics หนึ่งในบริษัทที่ประสบความสำเร็จและลึกลับที่สุดในโลก ดำเนินกิจการและเป็นผู้นำมาหลายปีแล้ว และมีแนวโน้มว่าจะทำเช่นนั้นต่อไป",
    "0009": "ร่วมงานกับเรา",
    "0010": "เราเชื่อเป็นอย่างยิ่งว่าทรัพยากรมนุษย์เป็นทรัพย์สินที่มีค่าที่สุด ที่ Thai Samsung Electronics เรามอบโอกาสไม่รู้จบเพื่อเปิดเผยทักษะของคุณและก้าวข้ามขีดจำกัดของคุณ",
    "0011": "คลิกดูตำแหน่งที่เปิดรับ",
    "0012": "ด้วยความทุ่มเทอย่างจริงใจของพนักงานของเรา Thai Samsung Electronics ได้บรรลุผลงานอันยอดเยี่ยมตั้งแต่ก่อตั้งบริษัท",
    "0013": "ตำแหน่งงานที่เปิดรับ",
    "0014": "สนใจสมัครตำแหน่งนี้",
    "0015": "คุณสมบัติ",
    "0016": "รายละเอียดงาน/หน้าที่ความรับผิดชอบ",
    "0017": "ประสบการณ์ทำงาน",
    "0018": "ร่วมเป็นส่วนหนึ่งของครอบครัวไทยซัมซุง",
    "0019": "ฝากประวัติสมัครงาน",
    "0020": "ตำแหน่งงานที่สมัคร",
    "0021": "คำนำหน้าชื่อ",
    "0022": "First Name & Last Name | English",
    "0023": "ชื่อ และ นามสกุล | ภาษาไทย",
    "0024": "เบอร์โทรศัพท์",
    "0025": "สายงานที่สนใจ",
    "0026": "บอกเราเกี่ยวกับสายงานที่คุณสนใจ",
    "0027": "เลขประจำตัวประชาชน",
    "0028": "เลขประจำตัวประชาชน 13 หลัก",
    "0029": "ระดับการศึกษา",
    "0030": "สาขาวิชา",
    "0031": "ประสบการณ์การทำงาน (ปี)",
    "0032": "เงินเดือนล่าสุด (บาท/เดือน)",
    "0033": "เงินเดือนที่คาดหวัง (บาท/เดือน)",
    "0034": "เงินเดือนที่คาดหวัง",
    "0035": "ทรานสคริป (.pdf / ขนาดไฟล์ไม่เกิน 10MB)",
    "0036": "ประวัติส่วนตัว (.pdf /ขนาดไฟล์ไม่เกิน 10MB)",
    "0037": "***ข้อมูลส่วนบุคคลที่ส่งผ่านแบบฟอร์มนี้บริษัทดำเนินการจัดเก็บ รวบรวม เก็บรักษา ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล เพื่อใช้ในการบริหารงานบุคคลของบริษัทฯ และให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล โดยบริษัทฯ จะเก็บรักษาข้อมูลดังกล่าวไว้เป็นความลับหากต้องการลบข้อมูลส่วนบุคคลของตนเองสามารถติดต่อแจ้งความประสงค์ได้ที่ E-mail : tse.recruit@samsung.com",
    "0038": "สมัคร",
    "0039": "ยอมรับ",
    "0040": "บัตรประจำตัวประชาชน เฉพาะหน้าบัตรเท่านั้น (.pdf)",
    "0041": "ทำความรู้จักกับไทยซัมซุงอิเลคโทรนิคส์",
    "0042": "ไทยซัมซุง อิเลคโทรนิคส์ มีความมุ่งมั่นที่จะปฏิบัติตามมาตรฐานสูงสุดในการดำเนินธุรกิจตลอดจนบริหารจัดการห่วงโซ่อุปทานอย่างมีความรับผิดชอบ เพื่อสร้างระบบนิเวศทางธุรกิจที่ยั่งยืนโดยร่วมมือกับบรรดาซัพพลายเออร์ของเราในทั่วโลก โดยอยู่บนพื้นฐานของหลักการในเรื่องความยุติธรรม การเปิดกว้าง และการบรรลุความสำเร็จร่วมกัน เราสนับสนุนให้ซัพพลายเออร์ของเราดำเนินงานโดยปฏิบัติตามกฎหมายและมาตรฐานระดับโลกเพื่อให้บรรลุความรับผิดชอบทางสังคมและสิ่งแวดล้อม นอกจากนี้ เรายังใช้ระบบการจัดการความเสี่ยง เพื่อลดการละเมิดสิทธิมนุษยชนและลดผลกระทบต่อสิ่งแวดล้อมมีมาจากการดำเนินธุรกิจ",
    "0043": "การบริหารด้านการปฏิบัติตามข้อกำหนดสำหรับซัพพลายเออร์ ถือเป็นกระบวนการในการปกป้องบริษัทฯและพนักงาน ตลอดจนผู้มส่วนได้ส่วนเสีย ต่อความเสียหายของการบริหารและภาพลักษณ์ขององค์กร เนื่องจากความเสี่ยงในการปฏิบัติตามบรรทัดฐานสากลและความรับผิดชอบต่อสังคมที่เกี่ยวข้องกับกิจกรรมของบริษัท",
    "0043": "การบริหารด้านการปฏิบัติตามข้อกำหนดสำหรับซัพพลายเออร์ ถือเป็นกระบวนการในการปกป้องบริษัทฯและพนักงาน ตลอดจนผู้มส่วนได้ส่วนเสีย ต่อความเสียหายของการบริหารและภาพลักษณ์ขององค์กร เนื่องจากความเสี่ยงในการปฏิบัติตามบรรทัดฐานสากลและความรับผิดชอบต่อสังคมที่เกี่ยวข้องกับกิจกรรมของบริษัท",
    "0044": "ซัพพลายเออร์ถูกร้องขอให้นำข้อกำหนดของซัมซุงฯไปใช้และปฏิบัติตามข้อกำหนดสากลและหลักความรับผิดชอบต่อสังคมอย่างต่อเนื่อง เพื่อก่อให้เกิดเสถียรภาพในระบบห่วงโซ่อุปทานอันนำมาซึ่งผลประโยชน์ร่วมกันทั้งของซัมซุงฯและสำหรับซัพพลายเออร์",
    "0045": "ดังนั้นเพื่อเป็นการสนับสนุนซัพพลายเออร์ ของเราให้บรรลุศักยภาพ และปฏิบัติตาม หลักจรรยาบรรณสำหรับซัพพลายเออร์ เราจึงสร้างเว็ปไซต์นี้ขึ้นมาเพื่อสร้างสมรรถนะของซัพพลายเออร์ โดย เราปรับเปลี่ยนหลักจรรยาบรรณสำหรับซัพพลายเออร์ให้สอดคล้องกับหลักจรรยาบรรณของพันธมิตรธุรกิจที่รับผิดชอบ (RBA) และแจ้งการอัปเดตข้อมูลให้บรรดาซัพพลายเออร์ของเราทราบ นอกจากนี้ เรายังจัดหาคู่มือเพื่อช่วยให้พวกเขาสามารถปฏิบัติตามหลักจรรยาบรรณ และดำเนินการบริหารงานโดยสอดคล้องกับกฎระเบียบ เพื่อให้แน่ใจว่าซัพพลายเออร์ของเราจะมีข้อได้เปรียบด้านการแข่งขันในทั่วโลก",
    "0046": "ดังนั้นเพื่อเป็นการสนับสนุนซัพพลายเออร์ ของเราให้บรรลุศักยภาพ และปฏิบัติตาม หลักจรรยาบรรณสำหรับซัพพลายเออร์ เราจึงสร้างเว็ปไซต์นี้ขึ้นมาเพื่อสร้างสมรรถนะของซัพพลายเออร์ โดย เราปรับเปลี่ยนหลักจรรยาบรรณสำหรับซัพพลายเออร์ให้สอดคล้องกับหลักจรรยาบรรณของพันธมิตรธุรกิจที่รับผิดชอบ (RBA) และแจ้งการอัปเดตข้อมูลให้บรรดาซัพพลายเออร์ของเราทราบ นอกจากนี้ เรายังจัดหาคู่มือเพื่อช่วยให้พวกเขาสามารถปฏิบัติตามหลักจรรยาบรรณ และดำเนินการบริหารงานโดยสอดคล้องกับกฎระเบียบ เพื่อให้แน่ใจว่าซัพพลายเออร์ของเราจะมีข้อได้เปรียบด้านการแข่งขันในทั่วโลก",
    "0047": "ติดต่อบริษัท ไทยซัมซุง อิเลคโทรนิคส์",
    "0048": "สถานที่ตั้ง",
    "0049": "จัดตั้งขึ้นเมื่อ",
    "0050": "ผู้ถือหุ้น",
    "0051": "เรายังมีตำแหน่งอื่นที่ยังไม่เปิดรับสมัคร แต่คุณสามารถสมัครไว้ก่อนได้ คลิกเพื่อดู",
    "0052": "ดู ",
    "0053": "ฝากประวัติตำแหน่งงานที่ยังไม่เปิดรับสมัคร",
    "0054": "ปี ",
    "0055": "<< กลับไปตำแหน่งงานที่เปิดรับ",
    "0056": "กรุณากรอกสาขาวิชาของคุณ",
    "0057": "แนบเอกสาร ",
    "0058": "ค่านิยมที่กำหนดจิตวิญญาณของ Samsung",
    "0059": "บุคลากร ",
    "0060": "ความเป็นเลิศ ",
    "0061": "ความเปลี่ยนแปลง ",
    "0062": "คุณธรรม ",
    "0063": "ความเจริญรุ่งเรืองร่วมกัน ",
    "0064": "องค์ประกอบหลักของบริษัท ก็คือ บุคลากรที่ Samsung เรามุ่งมั่นที่จะมอบโอกาศให้แก่บุคลากร เพื่อให้สามารถทำงานได้อย่างเต็มศักยภาพ",
    "0065": "ทุกสิ่งที่เราทำที่ Samsung เป็นผลมาจากความหลงใหลในความเป็นเลิศอย่างแท้จริง ด้วยความมุ่งมั่นในการพัฒนาผลิตภัณฑ์และบริการที่ดีที่สุดสำหรับตลาด",
    "0066": "เช่นเดียวกับที่เราดำเนินการมาตลอดนับจากก่อตั้ง เราพยายามที่จะมองไปข้างหน้าสู่อนาคต พร้อมทั้งคาดการณ์ความต้องการของตลาด เพื่อให้เราสามารถบริหารจัดการบริษัทสู่ความสำเร็จในระยะยาว ",
    "0067": "การดำเนินงานตามหลักจรรยาบรรณคือรากฐานสำคัญสำหรับธุรกิจของเรา ทุกสิ่งที่เราทำล้วนสอดคล้องตามหลักศีลธรรม เพื่อให้เกิดความยุติธรรม ความเคารพ ต่อทุกฝ่ายที่เกี่ยวข้อง และความโปร่งใสสูงสุด",
    "0068": "Samsung มุ่งมั่นที่จะเป็นพลเมืองธุรกิจที่รับผิดชอบต่อสังคมและสิ่งแวดล้อมในทุกชุมชนทั่วโลก ",
    "0069": "ไทยซัมซุง",
    "0070": "ขับเคลื่อนธุรกิจในไทยด้วยความใส่ใจ ",
    "0071": "ฐานผลิตเครื่องใช้ไฟฟ้าแห่งแรกนอกเกาหลีใต้ และใหญ่ที่สุดในโลก มีประเภทสินค้ามากที่สุดในโรงงาน 28 แห่งทั่วโลก แบ่งเป็น 6 กลุ่มสินค้าหลัก",
    "0072": "ผู้ผลิตเครื่องใช้ไฟฟ้าภายในบ้าน 6 กลุ่มสินค้าหลัก",
    "0073": "เครื่องล้างจาน ",
    "0074": "ตู้เย็น ",
    "0075": "เครื่องซักผ้า ",
    "0076": "เตาอบ ",
    "0077": "เครื่องฟอกอากาศ ",
    "0078": "เครื่องปรับอากาศ",
    "0079": "กิจกรรมเพื่อสังคมของไทยซัมซุง (CSR)",
    "0080": "Samsung STEM Education Room ",
    "0081": "ไทยซัมซุงส่งมองศูนย์การเรียนรู้ ให้กับโรงเรียนวัดหนองคล้า อ.ศรีราชา จ.ชลบุรี เป็นศูนย์การเรียนรู้สำหรับเด็กเล็กอนุบาลและประถมศึกษา เพื่อใช้เป็นห้องเรียนเสริมทักษะเพิ่มเติม และเป็นที่พักผ่อน",
    "0082": "แบ่งปันหน้ากากผ้าให้น้อง ",
    "0083": "ไทยซัมซุงร่วมแบ่งปันหน้ากากผ้าให้โรงเรียนในพื้นที่ใกล้เคียง เพื่อป้องกันเชื้อไวรัสโควิด-19",
    "0084": "Blood Donation ",
    "0085": "ไทยซัมซุงได้ร่วมกับสภากาชาดชลบุรี จัดตั้งหน่วยรับบริจาคโลหิตในพื้นที่บริษัท ",
    "0086": "อาสาไทยซัมซุง ",
    "0087": "ร่วมกับเจ้าหน้าที่เทศบาลเมืองแสนสุขร่วมกันเก็บขยะและทำความสะอาดแนวชายหาดบางแสน ",
    "0088": "สวนร่มเย็นหนองคล้า ",
    "0089": "บริษัทร่วมกับน้องๆนักเรียนและคณะอาจารย์ของโรงเรียนวัดหนองคล้า ช่วยกันปรับปรุงพื้นที่สวนหย่อมและบ่อทราย ",
    "0090": "ภารกิจและค่านิยมของเรา ",
    "0091": "คำมั่นสัญญาของเราที่จะดำเนินการอย่างมีความรับผิดชอบ ในฐานะของบริษัทที่เป็นผู้นำระดับโลก",
    "0092": "Samsung มุ่งมั่นที่จะปฏิบัติตามกฎหมายและกฎระเบียบของท้องถิ่น ตลอดจนบังคับใช้หลักจรรยาบรรณกับพนักงานทุกคนอย่างเคร่งครัด เราเชื่อว่าการจัดการอย่างมีจริยธรรมนั้นไม่เพียงแต่เป็นเครื่องมือที่ช่วยให้สามารถตอบสนองการเปลี่ยนแปลงอย่างรวดเร็วของสภาพแวดล้อมทางธุรกิจทั่วโลกเท่านั้น แต่นี่ยังเป็นเครื่องมือสร้างความไว้วางใจให้ผู้มีส่วนได้ส่วนเสียต่าง ๆ รวมถึงลูกค้า ผู้ถือหุ้น พนักงาน คู่ค้าทางธุรกิจ และชุมชนท้องถิ่นอีกด้วย ด้วยจุดมุ่งหมายที่จะเป็นหนึ่งในบริษัทที่มีจริยธรรมที่สุดในโลก Samsung ฝึกอบรมพนักงานอย่างต่อเนื่อง และใช้ระบบเฝ้าตรวจสอบ ในขณะที่บริหารจัดการองค์กรด้วยวิธีการที่เป็นธรรมและโปร่งใส",
    "0093": "พันธกิจและแนวทางของเรา ",
    "0094": "Samsung ปฏิบัติตามปรัชญา Oss ทางธุรกิจง่าย ๆ นั่นคือ อุทิศความสามารถและเทคโนโลยีของเราเพื่อสร้างผลิตภัณฑ์และบริการที่เหนือกว่า ซึ่งมีส่วนร่วมสร้างสรรค์สังคมโลกให้ดีกว่าเดิม เพื่อบรรลุเป้าหมายนี้ Samsung จึงให้คุณค่าแก่บุคลากรและเทคโนโลยีของตนอย่างสูง ",
    "0095": "ค่านิยมที่กำหนดจิตวิญญาณของ Samsung ",
    "0096": "Samsung เชื่อว่าการดำเนินชีวิตตามค่านิยมที่แข็งแกร่งเป็นกุญแจสำคัญของการดำเนินธุรกิจที่ดี นี่คือเหตุผลที่ค่านิยมหลักเหล่านี้ ตลอดจนหลักจรรยาบรรณที่เคร่งครัด จึงเป็นหัวใจสำคัญของการตัดสินใจทุกอย่างที่บริษัททำ ",
    "0097": "หลักการทางธุรกิจห้าประการของ Samsung ",
    "0098": "เพื่อแสดงความมุ่งมั่นด้านความรับผิดชอบทางสังคมขององค์กร (CSR) ในฐานะของบริษัทชั้นนำของโลก Samsung Electronics จึงประกาศ “หลักการทางธุรกิจห้าประการของ Samsung” ในปี ค.ศ. 2005 หลักการดังกล่าวเป็นรากฐานของหลักจรรยาบรรณระดับโลก ซึ่งสอดคล้องกับมาตรฐานทางกฎหมายและจริยธรรม และการมีความรับผิดชอบทางสังคมขององค์กร ",
    "0099": "1. สนับสนุนผู้คนให้สามารถทำสิ่งต่าง ๆ อย่างดีที่สุด ",
    "0100": "บนพื้นฐานของทรัพยากรบุคคลและเทคโนโลยี ",
    "0101": "ขยายการพัฒนาทรัพยากรมนุษย์และจุดเด่นด้านเทคนิคด้วยหลักการบริหาร",
    "0102": "เพิ่มการเสริมพลังเชิงบวกร่วมกันของระบบการจัดการทั้งหมดด้วยทรัพยากรมนุษย์และเทคโนโลยี ",
    "0103": "2. เป้าหมายภายในของเรา ",
    "0104": "สร้างผลิตภัณฑ์และบริการที่ดีที่สุด ",
    "0105": "สร้างผลิตภัณฑ์และบริการที่ให้ความพึงพอใจสูงสุดแก่ลูกค้า ",
    "0106": "รักษาตำแหน่งอันดับ 1 ของโลกในสายธุรกิจเดียวกัน ",
    "0107": "3. โลกภายนอก Samsung ",
    "0108": "และมีส่วนร่วมสร้างสรรค์สังคม ",
    "0109": "มีส่วนร่วมสร้างประโยชน์ส่วนรวมและชีวิตที่สมบูรณ์ ",
    "0110": "ดำเนินการตามประกาศภารกิจโดยสมาชิกของชุมชน ",
    "0111": "1. เราปฏิบัติตามมาตรฐานทางกฎหมายและจริยธรรม ",
    "0112": "เราเคารพศักดิ์ศรีและความหลากหลายของปัจเจกบุคคล ",
    "0113": "เราเคารพสิทธิมนุษยชนขั้นพื้นฐานของทุกผู้คน ไม่อนุญาตให้ใช้แรงงานบังคับ การขูดรีดค่าแรง และการใช้แรงงานทาสเด็กไม่ว่าในกรณีใด ๆ เราไม่เลือกปฏิบัติทางสัญชาติ เชื้อชาติ เพศ ศาสนา ฯลฯ และปฏิบัติต่อผู้มีส่วนได้ส่วนเสียและทุกฝ่าย เช่น ลูกค้าหรือพนักงานอย่างเท่าเทียม ",
    "0114": "เราแข่งขันอย่างเป็นธรรมโดยปฏิบัติตามกฎหมายและจริยธรรมทางธุรกิจ ",
    "0115": "เราปฏิบัติตามกฎระเบียบทุกข้อของประเทศอื่น ๆ และของชุมชนระดับภูมิภาค เคารพกติกาการแข่งขันทางการตลาด และแข่งขันอย่างเป็นธรรม เราไม่แสวงหากำไรที่ไม่เป็นธรรมด้วยวิธีการที่ผิดกฎหมายโดยการผิดจริยธรรมทางการค้า เราไม่ให้หรือรับของขวัญตอบแทน สินบน หรือข่มขู่ผู้คนในการดำเนินกิจกรรมทางธุรกิจ ",
    "0116": "เรารักษาความโปร่งใสของบัญชีผ่านการบันทึกบัญชีที่ถูกต้อง ",
    "0117": "เราบันทึกและจัดการธุรกรรมทั้งหมดด้วยความถูกต้อง เพื่อให้ผู้มีส่วนได้ส่วนเสียทั้งหมดเข้าใจอย่างชัดเจนว่ากิจกรรมทางธุรกิจของเราเป็นไปตามมาตรฐานการบัญชีที่ใช้ทั่วโลก ตลอดจนกฎระเบียบทางบัญชีของแต่ละประเทศ ตามที่กฎหมายกำหนดไว้ เรามีความโปร่งใสในด้านข้อมูลของบริษัทและแง่มุมหลักในการบริหารจัดการ เช่น การเปลี่ยนแปลงทางการเงินในบริษัท ",
    "0118": "เราไม่ยุ่งเกี่ยวกับการเมืองและรักษาความเป็นกลาง ",
    "0119": "เราเคารพสิทธิทางการเมืองและการตัดสินใจทางการเมืองของบุคคล และไม่ดำเนินกิจกรรมเกี่ยวกับการเมืองภายในบริษัท เราไม่ใช้เงินทุน กำลังคน สิ่งอำนวยความสะดวก ฯลฯ ของบริษัท เพื่อวัตถุประสงค์ทางการเมือง ",
    "0120": "2. เรารักษาวัฒนธรรมองค์กรที่สะอาด ",
    "0121": "เราแยกชีวิตส่วนตัวและชีวิตทางสาธารณะออกจากกิจกรรมทางธุรกิจทั้งหมดอย่างเคร่งครัด ",
    "0122": "ในกรณีที่มีข้อขัดแย้งระหว่างบริษัทและบุคคล เราจะให้ความสำคัญกับผลประโยชน์ที่ถูกต้องตามกฎหมายของบริษัทมากกว่า เราไม่แสวงหาผลประโยชน์ส่วนตัวโดยใช้ทรัพย์สินของบริษัทและตำแหน่งของตนเองในบริษัท และไม่ยุ่งเกี่ยวกับกิจกรรมที่ไม่เหมาะสมใด ๆ เช่น การใช้งานในทางที่ผิดหรือการยักยอกทรัพย์สินของบริษัท เราไม่ยุ่งเกี่ยวกับหลักทรัพย์ในตลาดหลักทรัพย์ เช่น การซื้อและขายหุ้น โดยใช้ข้อมูลที่ได้มาอย่างเป็นทางการ ",
    "0123": "เราคุ้มครองและเคารพทรัพย์สินทางปัญญาของบริษัทและบุคคลอื่น ๆ ",
    "0124": "เราคุ้มครองและเคารพทรัพย์สินทางปัญญาของบริษัทและบุคคลอื่น ๆ ห้ามนำทรัพย์สินทางปัญญาและข้อมูลที่เป็นความลับออกจากบริษัทโดยไม่ได้รับอนุญาตหรืออนุมัติล่วงหน้า เราเคารพทรัพย์สินทางปัญญาของผู้อื่น และไม่ยุ่งเกี่ยวกับการละเมิดใด ๆ เช่น การใช้งาน การทำซ้ำ การแจกจ่าย การเปลี่ยนแปลง ฯลฯ โดยไม่ได้รับอนุญาต",
    "0125": "เราสร้างบรรยากาศที่ดี ",
    "0126": "เราไม่อนุญาตพฤติกรรมใด ๆ ที่อาจเป็นอันตรายต่อความสัมพันธ์ระหว่างเพื่อนร่วมงาน เช่น การล่วงละเมิดทางเพศ การทำธุรกรรมทางการเงิน หรือความรุนแรง เราไม่สร้างฝักฝ่ายที่อาจทำให้เกิดความไม่ลงรอยกันภายในกลุ่ม และไม่จัดกลุ่มส่วนตัว ",
    "0127": "3. เราเคารพลูกค้า ผู้ถือหุ้น และพนักงาน ",
    "0128": "เราให้ความพึงพอใจของลูกค้าเป็นสิ่งสำคัญที่สุดของเรา ",
    "0129": "เราให้ผลิตภัณฑ์และบริการที่ตรงตามความต้องการและความคาดหวังของลูกค้า เรามีความสัมพันธ์ที่จริงใจและเป็นมิตรกับลูกค้าของเรา และยอมรับข้อเสนอแนะและข้อร้องเรียนต่าง ๆ ด้วยความสุภาพ นอกจากนี้ เรายังให้ความสำคัญกับข้อมูลและดำเนินการอย่างเหมาะสม ",
    "0130": "เรามุ่งบริหารจัดการโดยมุ่งเน้นคุณค่าสำหรับผู้ถือหุ้น ",
    "0131": "เราให้ผลประโยชน์ระยะยาวแก่ผู้ถือหุ้นด้วยการลงทุนที่สมเหตุสมผลและการปรับปรุงประสิทธิภาพการดำเนินงาน เราสร้างผลประโยชน์ที่มั่นคงผ่านกิจกรรมการบริหารจัดการที่สม่ำเสมอ และเพิ่มมูลค่าทางตลาดของบริษัท นอกจากนี้เรายังเคารพสิทธิ ความต้องการ และความคิดที่สมเหตุสมผลของผู้ถือหุ้น ",
    "0132": "เราให้ผลประโยชน์ระยะยาวแก่ผู้ถือหุ้นด้วยการลงทุนที่สมเหตุสมผลและการปรับปรุงประสิทธิภาพการดำเนินงาน เราสร้างผลประโยชน์ที่มั่นคงผ่านกิจกรรมการบริหารจัดการที่สม่ำเสมอ และเพิ่มมูลค่าทางตลาดของบริษัท นอกจากนี้เรายังเคารพสิทธิ ความต้องการ และความคิดที่สมเหตุสมผลของผู้ถือหุ้น ",
    "0133": "เราให้โอกาสพนักงานทุกคนอย่างเท่าเทียมกัน และปฏิบัติต่อพวกเขาอย่างเป็นธรรมโดยพิจารณาตามความสามารถและผลการดำเนินงาน เราให้คำแนะนำในการพัฒนาตนเองอย่างต่อเนื่องของพนักงาน และสนับสนุนการปรับปรุงที่จำเป็นในการปฏิบัติหน้าที่ นอกจากนั้น เรายังสร้างสภาพแวดล้อมการทำงานที่พนักงานสามารถทำงานได้อย่างอิสระและสร้างสรรค์ ",
    "0134": "4. เราห่วงใยสิ่งแวดล้อม ความปลอดภัยและสุขภาพ ",
    "0135": "เรามุ่งบริหารจัดการอย่างเป็นมิตรกับสิ่งแวดล้อม ",
    "0136": "เราปฏิบัติตามมาตรฐานสากลว่าด้วยการปกป้องสิ่งแวดล้อม กฎหมายที่เกี่ยวข้อง ตลอดจนกฎหมายลูกและกฎระเบียบภายใน เราพยายามอย่างต่อเนื่องในการรักษาสิ่งแวดล้อมในกิจกรรมทางธุรกิจทั้งหมด เช่น การพัฒนา การผลิต การขาย ฯลฯ เราเป็นผู้นำในการใช้ทรัพยากรอย่างมีประสิทธิภาพ เช่น การรีไซเคิลทรัพยากร ",
    "0137": "เราเน้นย้ำความสำคัญของความปลอดภัยและสุขภาพของผู้คน ",
    "0138": "เราปฏิบัติตามมาตรฐานสากลว่าด้วยความปลอดภัย กฎหมายที่เกี่ยวข้อง ตลอดจนกฎหมายลูกและกฎระเบียบภายใน เราเคารพกฎระเบียบด้านความปลอดภัย สร้างสภาพแวดล้อมการทำงานที่สะดวกสบาย และป้องกันอุบัติเหตุจากความประมาท ",
    "0139": "5. เราเป็นพลเมืองภาคองค์กรที่รับผิดชอบต่อสังคม ",
    "0140": "เราปฏิบัติตามภาระหน้าที่ขั้นพื้นฐานอย่างซื่อสัตย์ในฐานะของพลเมืองภาคองค์กร ",
    "0141": "เราปฏิบัติตามภาระหน้าที่ขั้นพื้นฐานอย่างซื่อสัตย์ในฐานะของพลเมืองภาคองค์กร เราพยายามเพิ่มศรัทธาในความรับผิดชอบและภาระหน้าที่ของบริษัทในฐานะสมาชิกของชุมชนในระดับภูมิภาค เราทำงานอย่างหนักเพื่อการจ้างงานที่ปลอดภัยและเข้าใจความรับผิดชอบในการจ่ายภาษี ",
    "0142": "เราสร้างความสัมพันธ์แบบอยู่ร่วมกันและรุ่งโรจน์ไปด้วยกันกับเหล่าพันธมิตรของเรา ",
    "0143": "เราสร้างความสัมพันธ์แบบสองทางด้วยความไว้วางใจซึ่งกันและกันกับพันธมิตรรายต่าง ๆ พร้อมยกให้พันธมิตรเหล่านั้นเป็นผู้ร่วมทำงานด้านยุทธศาสตร์ เราใฝ่หาการเติบโตร่วมกันและมุ่งมั่นที่จะเพิ่มศักยภาพด้านการแข่งขันของพันธมิตรของเราด้วยการสนับสนุนที่เปี่ยมไปด้วยจริยธรรมและถูกต้องตามกฎหมาย ",
    "0144": "เราเคารพคุณลักษณะทางสังคมและวัฒนธรรมที่มีอยู่ และมีการจัดการเชิงร่วมมือ (ความรุ่งเรืองร่วมกัน/การร่วมมือกัน) ",
    "0145": "เราเคารพกฎหมาย วัฒนธรรม และค่านิยมของชุมชนระดับภูมิภาค และมีส่วนร่วมในการปรับปรุงคุณภาพชีวิต เราคอยเฝ้าติดตามการพัฒนาสังคมที่มีสุขภาพดีผ่านกิจกรรมสาธารณประโยชน์ เช่น การศึกษาวิจัยทางวิชาการและศิลปะวัฒนธรรม กิจกรรมการกีฬา ฯลฯ เรามีส่วนร่วมแบบเชิงรุกในในกิจกรรมบริการสังคม เช่น งานอาสา งานบรรเทาสาธารณภัย ฯลฯ ",
    "0146": "บริษัทคู่ค้า ",
    "0147": "ตำแหน่งงานที่เปิดรับ ",
    "0148": "นโยบายการเก็บรักษาข้อมูลส่วนบุคคล",
    "0149": "ฟอร์มสมัครงาน (.pdf /ขนาดไฟล์ไม่เกิน 10MB)",
    "0150": "เอกสารยินยอมข้อมูลส่วนบุคคล (.pdf /ขนาดไฟล์ไม่เกิน 10MB) ",
    "0151": "สำเนาทะเบียนบ้าน (.pdf /ขนาดไฟล์ไม่เกิน 10MB) ",
    "0152": "สำเนาบัตรประชาชน (.pdf /ขนาดไฟล์ไม่เกิน 10MB) ",
    "0153": "สลิปเงินเดือน (ไม่บังคับ) (.pdf /ขนาดไฟล์ไม่เกิน 10MB) ",
    "0154": "ตำแหน่งงานนี้เปิดรับสมัครแล้ว",
    // "0154": "ตำแหน่งงานที่คุณเคยฝากประวัติไว้ ได้เปิดรับสมัครแล้ว",
    "0155": "รายละเอียดงาน",
    // "0155": "รายละเอียดงานที่คุณได้ทำการสมัครไว้ ",
    "0156": "ตำแหน่ง ",
    "0157": "เงินเดือนที่คุณคาดหวัง (บาท/เดือน)",
    "0158": "สมัครไว้เมื่อ ",
    "0159": "ใช่, ฉันสนใจ",
    "0160": "คุณกดสนใจตำแหน่งนี้แล้ว",
    "0161": "ชื่อ (ไทย) ",
    "0162": "อีเมล",
    "0163": "หลักจรรยาบรรณแห่งพันธมิตรธุรกิจ",
    "0164": "หลักจรรยาบรรณแห่งพันธมิตรธุรกิจผู้มีความรับผิดชอบ",
    "0165": "การพัฒนาอย่างยั่งยืนทั่วโลก ",
    "0166": "ไทยซัมซุง อิเลคโทรนิคส์ มีความมุ่งมั่นที่จะปฏิบัติตามมาตรฐานสูงสุดในการดำเนินธุรกิจตลอดจนบริหารจัดการห่วงโซ่อุปทานอย่างมีความรับผิดชอบ เพื่อสร้างระบบนิเวศทางธุรกิจที่ยั่งยืนโดยร่วมมือกับบรรดาซัพพลายเออร์ของเราในทั่วโลก โดยอยู่บนพื้นฐานของหลักการในเรื่องความยุติธรรม การเปิดกว้าง และการบรรลุความสำเร็จร่วมกัน เราสนับสนุนให้ซัพพลายเออร์ของเราดำเนินงานโดยปฏิบัติตามกฎหมายและมาตรฐานระดับโลกเพื่อให้บรรลุความรับผิดชอบทางสังคมและสิ่งแวดล้อม นอกจากนี้ เรายังใช้ระบบการจัดการความเสี่ยง เพื่อลดการละเมิดสิทธิมนุษยชนและลดผลกระทบต่อสิ่งแวดล้อมมีมาจากการดำเนินธุรกิจ",
    "0167": "การบริหารด้านการปฏิบัติตามข้อกำหนดสำหรับซัพพลายเออร์ ถือเป็นกระบวนการในการปกป้องบริษัทฯและพนักงาน ตลอดจนผู้มส่วนได้ส่วนเสีย ต่อความเสียหายของการบริหารและภาพลักษณ์ขององค์กร เนื่องจากความเสี่ยงในการปฏิบัติตามบรรทัดฐานสากลและความรับผิดชอบต่อสังคมที่เกี่ยวข้องกับกิจกรรมของบริษัท ",
    "0168": "ซัพพลายเออร์ถูกร้องขอให้นำข้อกำหนดของซัมซุงฯไปใช้และปฏิบัติตามข้อกำหนดสากลและหลักความรับผิดชอบต่อสังคมอย่างต่อเนื่อง เพื่อก่อให้เกิดเสถียรภาพในระบบห่วงโซ่อุปทานอันนำมาซึ่งผลประโยชน์ร่วมกันทั้งของซัมซุงฯและสำหรับซัพพลายเออร์ ",
    "0169": "ดังนั้นเพื่อเป็นการสนับสนุนซัพพลายเออร์ ของเราให้บรรลุศักยภาพ และปฏิบัติตาม หลักจรรยาบรรณสำหรับซัพพลายเออร์ เราจึงสร้างเว็ปไซต์นี้ขึ้นมาเพื่อสร้างสมรรถนะของซัพพลายเออร์ โดย เราปรับเปลี่ยนหลักจรรยาบรรณสำหรับซัพพลายเออร์ให้สอดคล้องกับหลักจรรยาบรรณของพันธมิตรธุรกิจที่รับผิดชอบ (RBA) และแจ้งการอัปเดตข้อมูลให้บรรดาซัพพลายเออร์ของเราทราบ นอกจากนี้ เรายังจัดหาคู่มือเพื่อช่วยให้พวกเขาสามารถปฏิบัติตามหลักจรรยาบรรณ และดำเนินการบริหารงานโดยสอดคล้องกับกฎระเบียบ เพื่อให้แน่ใจว่าซัพพลายเออร์ของเราจะมีข้อได้เปรียบด้านการแข่งขันในทั่วโลก ",
    "0170": "ดังนั้นเพื่อเป็นการสนับสนุนซัพพลายเออร์ ของเราให้บรรลุศักยภาพ และปฏิบัติตาม หลักจรรยาบรรณสำหรับซัพพลายเออร์ เราจึงสร้างเว็ปไซต์นี้ขึ้นมาเพื่อสร้างสมรรถนะของซัพพลายเออร์ โดย เราปรับเปลี่ยนหลักจรรยาบรรณสำหรับซัพพลายเออร์ให้สอดคล้องกับหลักจรรยาบรรณของพันธมิตรธุรกิจที่รับผิดชอบ (RBA) และแจ้งการอัปเดตข้อมูลให้บรรดาซัพพลายเออร์ของเราทราบ นอกจากนี้ เรายังจัดหาคู่มือเพื่อช่วยให้พวกเขาสามารถปฏิบัติตามหลักจรรยาบรรณ และดำเนินการบริหารงานโดยสอดคล้องกับกฎระเบียบ เพื่อให้แน่ใจว่าซัพพลายเออร์ของเราจะมีข้อได้เปรียบด้านการแข่งขันในทั่วโลก ",
    "0171": "หลักจรรยาบรรณแห่งพันธมิตรธุรกิจผู้มีความรับผิดชอบ",
    "0172": "ไทยซัมซุง อิเลคทรอนิคส์ ได้ยึดหลักจรรยาบรรณแห่งพันธมิตรธุรกิจผู้มีความรับผิดชอบ (RBA) เป็นหลักในการปฏิบัติเพื่อให้มั่นใจได้ว่าบริษัทของเราตลอดจนบริษัทคู่ค้าทางธุรกิจ จะเคารพในสิทธิมนุษยชน สิทธิแรงงาน และให้เกียรติต่อกัน, จะจัดสรรสภาพการทำงานที่ปลอดภัยต่อสุขภาพของพนักงาน, จะดำเนินธุรกิจอย่างมีความรับผิดชอบและเป็นมิตรต่อสิ่งแวดล้อม, จะดำเนินธุรกิจอย่างซื่อสัตย์ยึดถือหลักจริยธรรมเป็นมาตรฐานสูงสุด และจะจัดการห่วงโซ่อุปทานอย่างมีความรับผิดชอบ เพื่อการพัฒนาอย่างยั่งยืน ",
    "0173": "ด้านแรงงาน",
    "0174": "สุขภาพและความปลอดภัย",
    "0175": "สิ่งแวดล้อม",
    "0176": "จริยธรรม",
    "0178": "การจัดการห่วงโซ่อุปทาน",
    "0179": "เคารพสิทธิมนุษยชนของแรงงานและปฏิบัติอย่างให้เกียรติ",
    "0180": "จัดสรรสภาพการทำงานที่ปลอดภัยต่อสุขภาพของพนักงาน",
    "0181": "ดำเนินธุรกิจอย่างมีความรับผิดชอบและเป็นมิตรต่อสิ่งแวดล้อม",
    "0182": "ดำเนินธุรกิจอย่างซื่อสัตย์ยึดถือหลักจริยธรรมเป็นมาตรฐานสูงสุด",
    "0183": "จัดการห่วงโซ่อุปทานอย่างมีความรับผิดชอบ เพื่อการพัฒนาอย่างยั่งยืน",
    "0184": "เสรีภาพในการเลือกงาน",
    "0185": "แรงงานเยาวชน",
    "0186": "ชั่วโมงการทำงาน",
    "0187": "ค่าจ้างสวัสดิการ",
    "0188": "การปฏิบัติอย่างมีมนุษยธรรม",
    "0189": "การไม่เลือกปฏิบัติ",
    "0190": "เสรีภาพในการสมาคม",
    "0191": "ความปลอดภัยในการทำงาน",
    "0192": "การเตรียมความพร้อมรับมือเหตุฉุกเฉิน",
    "0193": "การบาดเจ็บและความเจ็บป่วยจากการทำงาน",
    "0194": "สุขอนามัยอุตสาหกรรม",
    "0195": "งานที่ต้องใช้แรงงานทางกายภาพ",
    "0196": "การป้องกันอันตรายจากการใช้เครื่องจักรกล",
    "0197": "สุขอนามัย อาหาร และที่อยู่อาศัย",
    "0198": "ใบอนุญาตและรายงานด้านสิ่งแวดล้อม",
    "0199": "วัตถุอันตราย",
    "0200": "ขยะมูลฝอย",
    "0201": "การปล่อยมลภาวะทางอากาศ",
    "0202": "การจัดการน้ำ",
    "0203": "การใช่พลังงานและการปล่อยก๊าซเรือนกระจก",
    "0204": "ไม่มีการเอื้อผลประโยชน์ที่ไม่เหมาะสม",
    "0205": "การเปิดเผยข้อมูล",
    "0206": "ทรัพย์สินทางปัญญา",
    "0207": "ดำเนินธุรกิจ การโฆษณา และการแข่งขันอย่างเป็นธรรม",
    "0208": "การปกป้องผู้แจ้งเบาะแสและการไม่ตอบโต้",
    "0209": "ความเป็นส่วนตัว",
    "0210": "คำมั่นสัญญาของบริษัท",
    "0211": "ข้อจำกัดด้านวัสดุ",
    "0212": "การหาแหล่งแร่ธาตุโดยมีความรับผิดชอบ",
    "0213": "ความรับผิดอบต่อซัพพลายเออร์",
    "0214": "คู่มือความปลอดภัย",
    "0215": "คู่มือความปลอดภัยสำหรับพนักงาน",
    "0216": "ความปลอดภัยคือความสำคัญสูงสุดในการดำเนินธุรกิจ",
    "0217": "ความรับผิดชอบต่อซัพพลายเออร์",
    "0218": "เราสนับสนุนซัพพลายเออร์ของเราในการสร้างความได้เปรียบในการแข่งขันที่แข็งแกร่งขึ้น เพื่อสร้างระบบนิเวศขององค์กรที่ดีและห่วงโซ่อุปทานที่ยั่งยืน",
    "0219": "บริษัท ไทยซัมซุง อิเลคโทรนิคส์ จำกัด จะทำการส่งเสริมการพัฒนาสังคมอย่างยั่งยืน โดยการรักษาสภาพแวดล้อม การทำงานให้มีความปลอดภัย และเป็นมิตรต่อสิ่งแวดล้อม ภายใต้หลักธุรกิจซัมซุงที่ให้พันธะสัญญาว่า จะดูแลเอาใจใส่ในเรื่องสิ่งแวดล้อม สุขภาพ และความปลอดภัย โดยเน้นความสำคัญอันดับหนึ่งในเรื่องความปลอดภัยของพนักงานและของลูกค้า รวมทั้งการพัฒนผลิตภัณฑ์ให้เป็นมิตรต่อสิ่งแวดล้อม",
    "0220": "เสริมสร้างระบบการจัดการความปลอดภัยและสิ่งแวดล้อมที่เป็นสากล",
    "0221": "บริษัทฯ จะปฏิบัติตามกฎหมายและข้อกำหนดต่างๆ ที่เกี่ยวข้องทางด้านสิ่งแวดล้อม สุขภาพ ความปลอดภัย และการอนุรักษ์พลังงานอย่างเคร่งครัด เพื่อป้องกันอุบัติเหตุด้านความปลอดภัยและสิ่งแวดล้อมที่อาจจะเกิดขึ้น บริษัทฯ จะทำการฝึกอบรม ให้ความรู้ ส่งเสริมการมีส่วนร่วม และให้คำปรึกษาแก่พนักงาน เพื่อให้มีความสามารถในการบริหารจัดการระบบด้านความปลอดภัยและสิ่งแวดล้อมได้เป็นอย่างดี นอกจากนี้บริษัทฯ จะทำการสื่อสารเรื่องนโยบายและผลการปฏิบัติงานด้านสิ่งแวดล้อม สุขภาพ และความปลอดภัย ให้กับพนักงานทุกคน และผู้มีส่วนได้ส่วนเสียทุกภาคส่วนได้รับทราบ",
    "0222": "ความรับผิดชอบตลอดวงจรของผลิตภัณฑ์",
    "0223": "บริษัทฯ จะไม่ซื้อวัตถุดิบ ชิ้นส่วน และบรรจุภัณฑ์ที่ผลิตมาจากวัตถุอันตรายต้องห้าม บริษัทฯ จะออกแบบและดำเนินการผลิต โดยใช้หลักการในการเพิ่มประสิทธิภาพ การนำกลับมาใช้ซ้ำ และการรีไซเคิลเพื่อลดผลกระทบต่อสิ่งแวดล้อม",
    "0224": "ผลิตภัณฑ์ที่เป็นมิตรต่อสิ่งแวดล้อม",
    "0225": "บริษัทฯ มุ่งมั่นในการลดการปล่อยก๊าซเรือนกระจกและมลพิษต่างๆ โดยใช้เทคโนโลยีการผลิตที่สะอาดที่เหมาะสมที่สุดเพื่อลดปริมาณการใช้ทรัพยากร เช่น สารเคมี พลังงานไฟฟ้า น้ำ และส่งเสริมการนำของเสียมาใช้ซ้ำ หรือทำการรีไซเคิล",
    "0226": "ดำเนินการตามนโยบายการเปลี่ยนแปลงของสภาพภูมิอากาศ",
    "0227": "บริษัทได้เล็งเห็นว่าวิกฤตการเปลี่ยนแปลงสภาพภูมิอากาศเป็นปัญหาส่วนรวม ที่ทุกคนจะต้องช่วยกันในการรักษาสิ่งแวดล้อมโลก และบริษัทจะพยายามขยายการใช้พลังงานหมุนเวียน ลดการปล่อยก๊าซเรือนกระจก และสร้างสังคมคาร์บอนต่ำ เพื่อให้เราสามารถผ่านพ้นวิกฤตการเปลี่ยนแปลงสภาพภูมิอากาศไปได้",
    "0228": "การดูแลสภาพแวดล้อมในการทำงานให้ปลอดภัย",
    "0229": "พนักงานทุกคนมีหน้าที่ในการสนับสุนนและส่งเสริมวัฒนธรรมความปลอดภัย และเข้าร่วมในกิจกรรมด้านสิ่งแวดล้อม สุขภาพ และความปลอดภัยที่จัดขึ้น เช่น การตรวจร่างกายประจำปี การปรับปรุงพื้นที่ปฏิบัติงานให้มีความปลอดภัยมากขึ้น นอกจากนี้ บริษัทฯ จะดูแลพนักงาน และผู้ที่มาติดต่อไม่ให้ได้รับผลกระทบจากปัจจัยความเสี่ยงภายนอก เช่น อุบัติภัยธรรมชาติ อุบัติเหตุไฟไหม้ โรคติดต่อ ฯลฯ และบริษัทฯ จะจัดทำมาตรการตอบสนองต่อเหตฉุกเฉิน เพื่อป้องกันความสูญเสียจากเหตุการณ์ต่างๆ ที่อาจจะเกิดขึ้น",
    "0230": "การพัฒนา ส่งเสริมคู่ค้าให้เติบโตร่วมกัน",
    "0231": "บริษัทฯ จะสนับสนุนบริษัทคู่ค้าของเราให้ปฏิบัติตามหลักจริยธรรมอย่างถูกต้อง และบริษัทฯ จะทำการเผยแพร่ระบบจัดการความปลอดภัยและสิ่งแวดล้อม พร้อมทั้งเทคโนโลยีให้แก่บริษัทคู่ค้า เพื่อสร้างพันธมิตรที่ดำเนินธุรกิจที่เป็นมิตรต่อสิ่งแวดล้อม บริษัทฯ จะทำการสื่อสารกับชุมชน เพื่อแสดงถึงการดำเนินธุรกิจที่ซื่อสัตย์และโปร่งใส และเพิ่มความพยายามเป็นพิเศษเพื่อส่งเสริมการพัฒนาชุมชนให้ดียื่งขึ้น",
    "0232": "คลิกเพื่อดาวน์โหลดคู่มือความปลอดภัยของเรา",
    "0233": "การจัดซื้อจัดจ้าง",
    "0234": "ข่าวสารการจัดซื้อจัดจ้าง",
    "0235": "เข้าร่วมกับเรา",
    "0236": "ชื่อบริษัทภาษาอังกฤษ",
    "0237": "ประเภทธุรกิจ",
    "0238": "เว็บไซต์บริษัท",
    "0239": "รายละเอียดบริษัท",
    "0240": "แนบไฟล์แนะนำบริษัท (มากสุด 3 ไฟล์)",
    "0241": "ชื่อผู้ติดต่อ",
    "0242": "เบอร์โทรศัพท์ผู้ติดต่อ",
    "0243": "กลับ",
    "0244": "อ้างอิง",
    "0245": "สนใจเข้าร่วม",
    "0246": "เมลผู้ติดต่อ",
    "0247": "เอกสารเพิ่มเติม",
    "0248": "เราหวังว่าจะได้สานต่อความร่วมมือทางธุรกิจต่อไป",
    "0249": "ระยะเวลากิจกรรม",
    "0250": "สำเนาหนังสือรับรองผ่านการเกณฑ์ทหาร",
    "0251": "รายละเอียดเพิ่มเติม",
    "0252": "ชื่อบริษัทภาษาไทย",
    "0253": "ทุนจดทะเบียน",
    "0254": "วันจดทะเบียน",
    "0255": "เลขทะเบียนนิติบุคคล",
    "0256": "ปีที่นำส่งภาษีล่าสุด",
    "0257": "ประเภทบริษัท",
    "0258": "จังหวัด",
    "0259": "ลูกค้า",
    "0260": "สาขาที่",
    "0261": "สำนักงานใหญ่",
    "0262": "สาขา",
    "0263": "สมัครกิจกรรมนี้",
    "0264": "ข่าวสารการจัดซื้อจัดจ้าง",
    "0265": "เอกสารประกอบการลงทะเบียนคู่ค้า",
    "0266": "เงื่อนไขการเป็นลูกค้า",
    "0267": "แผนจัดซื้อจัดจ้าง",
    "0268": "ฝากประวัติ",
    "0269": "หนังสือรับรองบริษัท (ไม่เกิน 3 เดือนนับจากวันที่ยื่นขอลงทะเบียน)",
    "0270": "ภพ.20 (ต้องเป็นสำนักงานใหญ่ หรือสาขาที่จะเดินธุรกิจกับบริษัท ไทยซัมซุง เท่านั้น)",
    "0271": "สำเนาหน้าสมุดบัญชีธนาคาร",
    "0272": "หนังสือยืนยันการเปิดบัญชีธนาคาร",
    "0273": "สำเนาบัตรประชาชน / สำเนาพาสปอร์ต (กรณีเป็นต่างชาติ)",
    "0274": "ทุนจดทะเบียน มีรายละเอียดดังนี้",
    "0275": "ทุนจดทะเบียน 1,000,000 บาท",
    "0276": "อุปกรณ์เกี่ยวกับความปลอดภัย (ถุงมือ/รองเท้า/หน้ากาก)",
    "0277": "การฝึกอบรม",
    "0278": "เฟอร์นิเจอร์",
    "0279": "ของขวัญ, ของที่ระลึก",
    "0280": "ร้านอาหาร (โรงอาหาร/ร้านสวัสดิการ/โต๊ะจีน)",
    "0281": "สินค้าสินค้าเปลือง (เครื่องเขียน/ต้นไม้/ของใช้เบ็ดเตล็ดทั่วไป) (พิจารณาตามลักษณะการซื้อขายในแต่ละครั้ง)",
    "0282": "ทุนจดทะเบียนมากกว่ามูลค่างานแต่ไม่เกิน 3 ล้าน  (ทุนท่วมราคางาน แต่ไม่เกิน 3 ล้าน)",
    "0283": "งานก่อสร้าง",
    "0284": "งานบริการด้านสิ่งแวดล้อม อาชีวอนามัยและความปลอดภัย",
    "0285": "งานเครื่องจักร์และอุปกรณ์ อะไหล่",
    "0286": "งานบริการ (งานเช่า งานสั่งทำ)",
    "0287": "งานสาธารณูปโภค (ไฟฟ้า น้ำ แก๊ซ)",
    "0288": "ดำเนินธุรกิจมาแล้วไม่ต่ำกว่า 1 ปี",
    "0289": "บริษัทที่ตั้งต้องไม่เป็นรูปแบบโฮมออฟฟิศ",
    "0290": "บริษัทนำส่งภาษีถูกต้องตามกฎหมาย",
    "0291": "**ห้ามแนบไฟล์สำคัญของบริษัท (หนังสือรับรอง, ภพ20)",
    "0292": "555",
    "0293": "555",
    "0294": "555",
    "0295": "555",
    "0296": "555",
    "0297": "555",
    "0298": "555",
    "0299": "555",
    "0300": "555",
    "0301": "555",
    "0302": "555",
    "0303": "555",
    "0304": "555",
    "0305": "555",
    "0306": "555",
    "0307": "555",
    "0308": "555",
    "0309": "555",
    "0310": "555",
    "0311": "555",
    "0312": "555",
  },
  en:{
    "0001": "Home",
    "0002": "About us",
    "0003": "Contact us",
    "0004": "Career",
    "0005": "The Leading Technology Solutions",
    "0006": "Thai Samsung Electronics",
    "0007": "We want to make our corporate culture more open, flexible and innovative.",
    "0008": "One of the world's most successful and yet mysterious companies in the world, Thai Samsung Electronics, has been operating and leader for years and likely will continue for long time.",
    "0009": "Join Our Team",
    "0010": "We greatly believe that human resources is the most valuable asset. At Thai Samsung Electronics, we provide endless opportunities to unfold your skills and overcome your limits.",
    "0011": "Career Opening",
    "0012": "With the sincere devotion of our employees, Thai Samsung Electronics has achieved stellar performances since its foundation.",
    "0013": "We Are Hiring",
    "0014": "Apply Now",
    "0015": "Qualification",
    "0016": "Job Details/Responsibility Role",
    "0017": "Experience",
    "0018": "Be a part of us Thai Samsung family",
    "0019": "Apply Job",
    "0020": "Applied Position",
    "0021": "Title",
    "0022": "First Name & Last Name | English",
    "0023": "ชื่อ และ นามสกุล | ภาษาไทย",
    "0024": "Phone Number",
    "0025": "Desired Job",
    "0026": "Tell us about your desired job",
    "0027": "Id Card Number",
    "0028": "Id card number 13 digit",
    "0029": "Education Level",
    "0030": "Major",
    "0031": "Years of Professional Experience (Year)",
    "0032": "Latest salary Salary (Baht/Month)",
    "0033": "Expected Salary (Baht/Month)",
    "0034": "Expected Salary",
    "0035": "Transcript (.pdf / File size should be less than 10 MB)",
    "0036": "Resume (.pdf / File size should be less than 10 MB)",
    "0037": "***ข้อมูลส่วนบุคคลที่ส่งผ่านแบบฟอร์มนี้บริษัทดำเนินการจัดเก็บ รวบรวม เก็บรักษา ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล เพื่อใช้ในการบริหารงานบุคคลของบริษัทฯ และให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล โดยบริษัทฯ จะเก็บรักษาข้อมูลดังกล่าวไว้เป็นความลับหากต้องการลบข้อมูลส่วนบุคคลของตนเองสามารถติดต่อแจ้งความประสงค์ได้ที่ E-mail : tse.recruit@samsung.com",
    "0038": "Submit",
    "0039": "Accept, ",
    "0040": "ID Card front of card only (.pdf)",
    "0041": "Let to Know Thai Samsung Electronics",
    "0042": "ไทยซัมซุง อิเลคโทรนิคส์ มีความมุ่งมั่นที่จะปฏิบัติตามมาตรฐานสูงสุดในการดำเนินธุรกิจตลอดจนบริหารจัดการห่วงโซ่อุปทานอย่างมีความรับผิดชอบ เพื่อสร้างระบบนิเวศทางธุรกิจที่ยั่งยืนโดยร่วมมือกับบรรดาซัพพลายเออร์ของเราในทั่วโลก โดยอยู่บนพื้นฐานของหลักการในเรื่องความยุติธรรม การเปิดกว้าง และการบรรลุความสำเร็จร่วมกัน เราสนับสนุนให้ซัพพลายเออร์ของเราดำเนินงานโดยปฏิบัติตามกฎหมายและมาตรฐานระดับโลกเพื่อให้บรรลุความรับผิดชอบทางสังคมและสิ่งแวดล้อม นอกจากนี้ เรายังใช้ระบบการจัดการความเสี่ยง เพื่อลดการละเมิดสิทธิมนุษยชนและลดผลกระทบต่อสิ่งแวดล้อมมีมาจากการดำเนินธุรกิจ",
    "0043": "การบริหารด้านการปฏิบัติตามข้อกำหนดสำหรับซัพพลายเออร์ ถือเป็นกระบวนการในการปกป้องบริษัทฯและพนักงาน ตลอดจนผู้มส่วนได้ส่วนเสีย ต่อความเสียหายของการบริหารและภาพลักษณ์ขององค์กร เนื่องจากความเสี่ยงในการปฏิบัติตามบรรทัดฐานสากลและความรับผิดชอบต่อสังคมที่เกี่ยวข้องกับกิจกรรมของบริษัท",
    "0044": "ซัพพลายเออร์ถูกร้องขอให้นำข้อกำหนดของซัมซุงฯไปใช้และปฏิบัติตามข้อกำหนดสากลและหลักความรับผิดชอบต่อสังคมอย่างต่อเนื่อง เพื่อก่อให้เกิดเสถียรภาพในระบบห่วงโซ่อุปทานอันนำมาซึ่งผลประโยชน์ร่วมกันทั้งของซัมซุงฯและสำหรับซัพพลายเออร์",
    "0045": "ดังนั้นเพื่อเป็นการสนับสนุนซัพพลายเออร์ ของเราให้บรรลุศักยภาพ และปฏิบัติตาม หลักจรรยาบรรณสำหรับซัพพลายเออร์ เราจึงสร้างเว็ปไซต์นี้ขึ้นมาเพื่อสร้างสมรรถนะของซัพพลายเออร์ โดย เราปรับเปลี่ยนหลักจรรยาบรรณสำหรับซัพพลายเออร์ให้สอดคล้องกับหลักจรรยาบรรณของพันธมิตรธุรกิจที่รับผิดชอบ (RBA) และแจ้งการอัปเดตข้อมูลให้บรรดาซัพพลายเออร์ของเราทราบ นอกจากนี้ เรายังจัดหาคู่มือเพื่อช่วยให้พวกเขาสามารถปฏิบัติตามหลักจรรยาบรรณ และดำเนินการบริหารงานโดยสอดคล้องกับกฎระเบียบ เพื่อให้แน่ใจว่าซัพพลายเออร์ของเราจะมีข้อได้เปรียบด้านการแข่งขันในทั่วโลก",
    "0046": "ดังนั้นเพื่อเป็นการสนับสนุนซัพพลายเออร์ ของเราให้บรรลุศักยภาพ และปฏิบัติตาม หลักจรรยาบรรณสำหรับซัพพลายเออร์ เราจึงสร้างเว็ปไซต์นี้ขึ้นมาเพื่อสร้างสมรรถนะของซัพพลายเออร์ โดย เราปรับเปลี่ยนหลักจรรยาบรรณสำหรับซัพพลายเออร์ให้สอดคล้องกับหลักจรรยาบรรณของพันธมิตรธุรกิจที่รับผิดชอบ (RBA) และแจ้งการอัปเดตข้อมูลให้บรรดาซัพพลายเออร์ของเราทราบ นอกจากนี้ เรายังจัดหาคู่มือเพื่อช่วยให้พวกเขาสามารถปฏิบัติตามหลักจรรยาบรรณ และดำเนินการบริหารงานโดยสอดคล้องกับกฎระเบียบ เพื่อให้แน่ใจว่าซัพพลายเออร์ของเราจะมีข้อได้เปรียบด้านการแข่งขันในทั่วโลก",
    "0047": "Way to Contact Thai Samsung Electronics",
    "0048": "Location ",
    "0049": "Established",
    "0050": "Share Holder",
    "0051": "click to view other job positions offline",
    "0052": "view",
    "0053": "Job Positions Offline",
    "0054": "year ",
    "0055": "<< Back to online job",
    "0056": "Please input your major",
    "0057": "Attach Documents",
    "0058": "The values that define Samsung's spirit ",
    "0059": "People ",
    "0060": "Excellence ",
    "0061": "Change ",
    "0062": "Co-prosperity",
    "0063": "Integrity ",
    "0064": "Quite simply, a company is its people. At Samsung, we’re dedicated to giving our people a wealth of opportunities to reach their full potential.",
    "0065": "Everything we do at Samsung is driven by an unyielding passion for excellence and an unfaltering commitment to develop the best products and services on the market",
    "0066": "As we have done since our foundation, we set our sights on the future, anticipating market needs and demands so we can steer our company toward long-term success.",
    "0067": "Operating in an ethical way is the foundation of our business. Everything we do is guided by a moral compass that ensures fairness, respect for all stakeholders and complete transparency.",
    "0068": "Samsung is committed to becoming a socially and environmentally responsible corporate citizen in all of its communities worldwide.",
    "0069": "Thai Samsung ",
    "0070": "Driving business in Thailand with care ",
    "0071": "First electronics manufacturing base outside of South Korea and the largest in the world It has the most product types in 28 factories around the world divided into 6 main product groups.",
    "0072": "6 main product groups of home appliances manufacturers",
    "0073": "Dish Washer ",
    "0074": "Refrigerator ",
    "0075": "Washing",
    "0076": "Oven ",
    "0077": "Air Purifier ",
    "0078": "Air Conditioner ",
    "0079": "Thai Samsung Corporate Social Responsibility (CSR) Activities.",
    "0080": "Samsung STEM Education Room ",
    "0081": "ไทยซัมซุงส่งมองศูนย์การเรียนรู้ ให้กับโรงเรียนวัดหนองคล้า อ.ศรีราชา จ.ชลบุรี เป็นศูนย์การเรียนรู้สำหรับเด็กเล็กอนุบาลและประถมศึกษา เพื่อใช้เป็นห้องเรียนเสริมทักษะเพิ่มเติม และเป็นที่พักผ่อน",
    "0082": "แบ่งปันหน้ากากผ้าให้น้อง ",
    "0083": "ไทยซัมซุงร่วมแบ่งปันหน้ากากผ้าให้โรงเรียนในพื้นที่ใกล้เคียง เพื่อป้องกันเชื้อไวรัสโควิด-19",
    "0084": "Blood Donation ",
    "0085": "ไทยซัมซุงได้ร่วมกับสภากาชาดชลบุรี จัดตั้งหน่วยรับบริจาคโลหิตในพื้นที่บริษัท ",
    "0086": "อาสาไทยซัมซุง ",
    "0087": "ร่วมกับเจ้าหน้าที่เทศบาลเมืองแสนสุขร่วมกันเก็บขยะและทำความสะอาดแนวชายหาดบางแสน ",
    "0088": "สวนร่มเย็นหนองคล้า ",
    "0089": "บริษัทร่วมกับน้องๆนักเรียนและคณะอาจารย์ของโรงเรียนวัดหนองคล้า ช่วยกันปรับปรุงพื้นที่สวนหย่อมและบ่อทราย ",
    "0090": "Our mission & values",
    "0091": "Our promise to perform responsibly as a leading, global company.",
    "0092": "Samsung is committed to complying with local laws and regulations as well as applying a strict global code of conduct to all employees. It believes that ethical management is not only a tool for responding to the rapid changes in the global business environment, but also a vehicle for building trust with its various stakeholders including customers, shareholders, employees, business partners and local communities. With an aim to become one of the most ethical companies In the world, Samsung continues to train its employees and operate monitoring systems, while practicing fair and transparent corporate management.",
    "0093": "Our mission & approach ",
    "0094": "Samsung follows a simple business philosophy: to devote its talent and technology to creating superior products and services that contribute to a better global society. To achieve this, Samsung sets a high value on its people and technologies. ",
    "0095": "5The values that define Samsung's spirit",
    "0096": "Samsung believes that living by strong values is the key to good business. That’s why these core values, along with a rigorous code of conduct, are at the heart of every decision the company makes. ",
    "0097": "Five Samsung business principles",
    "0098": "In an expression of its commitment to corporate social responsibility as a world leading company, Samsung Electronics announced the “Five Samsung Business Principles” in 2005. The principles serve as the foundation for its global code of conduct in compliance with legal and ethical standards and the fulfillment of its corporate social responsibilities. ",
    "0099": "1. Supporting people to be their best",
    "0100": "On the basis of human resources & technologies ",
    "0101": "Make the extension of human resource development and technical superiority with management principles",
    "0102": "Increase the synergy effect of the whole management system through human resources and technologies ",
    "0103": "2. Our internal goals ",
    "0104": "Create the best products and services ",
    "0105": "Create the products and services that give customers the best satisfaction ",
    "0106": "Retain the 1st position in the world in the same line of business ",
    "0107": "3. Beyond Samsung ",
    "0108": "And contribute to society ",
    "0109": "Contribute for common interests and a rich life ",
    "0110": "Perform the mission statement by a member of the community ",
    "0111": "1. We comply with laws and ethical standards. ",
    "0112": "We respect the dignity and diversity of individuals. ",
    "0113": "We respect the basic human rights of all people. Forced labour, wage exploitation and child slavery are not allowed under any circumstances. We do not discriminate against nationality, race, gender, religion, etc. and treat all stakeholders and parties like customers or employees. ",
    "0114": "We compete fairly in accordance to law and business ethics. ",
    "0115": "We comply with all rules of other countries and regional communities, respect market competition order and compete in a fair way. We do not make unfair profits by unlawful means from bad trade ethics. We do not give or take compensation gifts, bribes or treat people while conducting business activities. ",
    "0116": "We maintain transparent accounting through accurate accounting records. ",
    "0117": "We record and manage all transactions accurately to make all stakeholders clearly understand our business activities comply with accounting standards that are used internationally, as well as accounting regulations of each country. As prescribed by law, we are transparent with company information and the main aspects of management, such as financial changes in the company. ",
    "0118": "We do not intervene in politics and maintain neutrality. ",
    "0119": "We respect political rights and political decisions of a person, and do not conduct political activity within the company. We do not use the company’s funds, manpower, facilities, etc. for political purposes. ",
    "0120": "2. We maintain a clean organisational culture. ",
    "0121": "We strictly keep one’s private and public life separate from all business activities. ",
    "0122": "In the case of a conflict between the company and a person, we give priority to the company’s legal interests. We do not espouse personal interests using the company’s property and own position and are not involved in any inappropriate activities such as misuse or embezzlement of the company’s assets. We do not have dealings with marketable securities like the buying and selling of stocks using officially acquired information. ",
    "0123": "We protect and respect the intellectual property of the company and other people. ",
    "0124": "Intellectual property and confidential information do not leave the company without prior permission or approval. We respect the intellectual property of others and are not involved in any acts of infringement like unauthorised use, reproduction, distribution, changes, etc. ",
    "0125": "We create a healthy atmosphere. ",
    "0126": "We do not allow any behaviour that could harm healthy peer relationships such as sexual harassment, money transactions or violence. We do not form factions that could cause incompatibility inside the group nor do we organise private groups. ",
    "0127": "3. We respect customers, shareholders and employees. ",
    "0128": "We make customer satisfaction our top priority. ",
    "0129": "We provide products and services that meet the needs and expectations of customers. We have a sincere and friendly relationship with our customers and accept suggestions and complaints with modesty. Also, we respect and honour and information. ",
    "0130": "We provide products and services that meet the needs and expectations of customers. We have a sincere and friendly relationship with our customers and accept suggestions and complaints with modesty. Also, we respect and honour and information. ",
    "0131": "We provide long-term benefits to shareholders through reasonable investments and improvement of operational efficiency. We create stable benefits through steady management activities and enhance the company’s market value. Moreover, we respect the shareholders’ rights, reasonable demands and ideas. ",
    "0132": "We make efforts for the improvement of the ‘quality of life’ of our employees. ",
    "0133": "We provide equal opportunities to all employees and treat them fairly according to their abilities and performance. We recommend continuous self-development of employees and support the improvements required to carry out duties. In addition, we make a work environment that can work autonomously and creatively. ",
    "0134": "4. We care for the environment, safety and health. ",
    "0135": "We pursue eco-friendly management. ",
    "0136": "We comply with international standards concerning environmental protection, related acts and subordinate statute and internal regulations. We make constant efforts for environmental protection in all business activities such as development, manufacturing, sales, etc. We take the lead in effective use of resources, like resource recycling. ",
    "0137": "We put emphasis on people’s safety and health. ",
    "0138": "We comply with international standards concerning safety, related acts and subordinate statute and internal regulations. We obey safety regulations, create a comfortable work environment and prevent negligent accidents. ",
    "0139": "5. We are a socially responsible corporate citizen. ",
    "0140": "We perform basic obligations faithfully as an enterprise citizen. ",
    "0141": "We make an effort to raise faith in the company’s responsibility and obligation as a member of the regional community. We work hard for safe employment creation and sincerely understand the responsibility of tax payments. ",
    "0142": "We build relationships of co-existence and co-prosperity with business partners. ",
    "0143": "We form two-way relationships based on mutual trust with our business partners and recognize them as strategic collaborators. We pursue collective growth and strive to strengthen the competitiveness of our partners through ethical and lawful support. ",
    "0144": "We respect the present social and cultural characteristics and have cooperative management (mutual prosperity/cooperation). ",
    "0145": "We respect the laws, culture and values of the regional community and contribute to the improvement of the quality of life. We keep an eye on developing a healthy society through public interest activities such as intellectual and artistic study, culture, sport activities, etc. We actively participate in social service activities like volunteer work, disaster relief, etc. ",
    "0146": "Business Contact",
    "0147": "Job Positions Online ",
    "0148": "Personal information retention policy",
    "0149": "Application form (.pdf / File size should be less than 10 MB)",
    "0150": "Privacy Policy agreement (.pdf / File size should be less than 10 MB)",
    "0151": "Copy of House Registration (.pdf / File size should be less than 10 MB)",
    "0152": "Copy of ID card  (.pdf / File size should be less than 10 MB)",
    "0153": "Payment slip (Not require) (.pdf / File size should be less than 10 MB)",
    "0154": "Job positions is openings.",
    // "0154": "Job positions you have applied is openings.",
    "0155": "Details",
    "0156": "Position",
    "0157": "Expected salary (Baht/Month)",
    "0158": "Submitted on date",
    "0159": "Yes, I'm interested",
    "0160": "You are interested",
    "0161": "Name (TH) ",
    "0162": "Email ",
    "0163": "Supplier Responsibility",
    "0164": "Responsible Business Alliance",
    "0165": "Advancing Sustainability Globally ",
    "0166": "ไทยซัมซุง อิเลคโทรนิคส์ มีความมุ่งมั่นที่จะปฏิบัติตามมาตรฐานสูงสุดในการดำเนินธุรกิจตลอดจนบริหารจัดการห่วงโซ่อุปทานอย่างมีความรับผิดชอบ เพื่อสร้างระบบนิเวศทางธุรกิจที่ยั่งยืนโดยร่วมมือกับบรรดาซัพพลายเออร์ของเราในทั่วโลก โดยอยู่บนพื้นฐานของหลักการในเรื่องความยุติธรรม การเปิดกว้าง และการบรรลุความสำเร็จร่วมกัน เราสนับสนุนให้ซัพพลายเออร์ของเราดำเนินงานโดยปฏิบัติตามกฎหมายและมาตรฐานระดับโลกเพื่อให้บรรลุความรับผิดชอบทางสังคมและสิ่งแวดล้อม นอกจากนี้ เรายังใช้ระบบการจัดการความเสี่ยง เพื่อลดการละเมิดสิทธิมนุษยชนและลดผลกระทบต่อสิ่งแวดล้อมมีมาจากการดำเนินธุรกิจ",
    "0167": "การบริหารด้านการปฏิบัติตามข้อกำหนดสำหรับซัพพลายเออร์ ถือเป็นกระบวนการในการปกป้องบริษัทฯและพนักงาน ตลอดจนผู้มส่วนได้ส่วนเสีย ต่อความเสียหายของการบริหารและภาพลักษณ์ขององค์กร เนื่องจากความเสี่ยงในการปฏิบัติตามบรรทัดฐานสากลและความรับผิดชอบต่อสังคมที่เกี่ยวข้องกับกิจกรรมของบริษัท ",
    "0168": "ซัพพลายเออร์ถูกร้องขอให้นำข้อกำหนดของซัมซุงฯไปใช้และปฏิบัติตามข้อกำหนดสากลและหลักความรับผิดชอบต่อสังคมอย่างต่อเนื่อง เพื่อก่อให้เกิดเสถียรภาพในระบบห่วงโซ่อุปทานอันนำมาซึ่งผลประโยชน์ร่วมกันทั้งของซัมซุงฯและสำหรับซัพพลายเออร์ ",
    "0169": "ดังนั้นเพื่อเป็นการสนับสนุนซัพพลายเออร์ ของเราให้บรรลุศักยภาพ และปฏิบัติตาม หลักจรรยาบรรณสำหรับซัพพลายเออร์ เราจึงสร้างเว็ปไซต์นี้ขึ้นมาเพื่อสร้างสมรรถนะของซัพพลายเออร์ โดย เราปรับเปลี่ยนหลักจรรยาบรรณสำหรับซัพพลายเออร์ให้สอดคล้องกับหลักจรรยาบรรณของพันธมิตรธุรกิจที่รับผิดชอบ (RBA) และแจ้งการอัปเดตข้อมูลให้บรรดาซัพพลายเออร์ของเราทราบ นอกจากนี้ เรายังจัดหาคู่มือเพื่อช่วยให้พวกเขาสามารถปฏิบัติตามหลักจรรยาบรรณ และดำเนินการบริหารงานโดยสอดคล้องกับกฎระเบียบ เพื่อให้แน่ใจว่าซัพพลายเออร์ของเราจะมีข้อได้เปรียบด้านการแข่งขันในทั่วโลก ",
    "0170": "ดังนั้นเพื่อเป็นการสนับสนุนซัพพลายเออร์ ของเราให้บรรลุศักยภาพ และปฏิบัติตาม หลักจรรยาบรรณสำหรับซัพพลายเออร์ เราจึงสร้างเว็ปไซต์นี้ขึ้นมาเพื่อสร้างสมรรถนะของซัพพลายเออร์ โดย เราปรับเปลี่ยนหลักจรรยาบรรณสำหรับซัพพลายเออร์ให้สอดคล้องกับหลักจรรยาบรรณของพันธมิตรธุรกิจที่รับผิดชอบ (RBA) และแจ้งการอัปเดตข้อมูลให้บรรดาซัพพลายเออร์ของเราทราบ นอกจากนี้ เรายังจัดหาคู่มือเพื่อช่วยให้พวกเขาสามารถปฏิบัติตามหลักจรรยาบรรณ และดำเนินการบริหารงานโดยสอดคล้องกับกฎระเบียบ เพื่อให้แน่ใจว่าซัพพลายเออร์ของเราจะมีข้อได้เปรียบด้านการแข่งขันในทั่วโลก ",
    "0171": "Responsible Business Alliance Code of Conduct ",
    "0172": "ไทยซัมซุง อิเลคทรอนิคส์ ได้ยึดหลักจรรยาบรรณแห่งพันธมิตรธุรกิจผู้มีความรับผิดชอบ (RBA) เป็นหลักในการปฏิบัติเพื่อให้มั่นใจได้ว่าบริษัทของเราตลอดจนบริษัทคู่ค้าทางธุรกิจ จะเคารพในสิทธิมนุษยชน สิทธิแรงงาน และให้เกียรติต่อกัน, จะจัดสรรสภาพการทำงานที่ปลอดภัยต่อสุขภาพของพนักงาน, จะดำเนินธุรกิจอย่างมีความรับผิดชอบและเป็นมิตรต่อสิ่งแวดล้อม, จะดำเนินธุรกิจอย่างซื่อสัตย์ยึดถือหลักจริยธรรมเป็นมาตรฐานสูงสุด และจะจัดการห่วงโซ่อุปทานอย่างมีความรับผิดชอบ เพื่อการพัฒนาอย่างยั่งยืน",
    "0173": "Labor ",
    "0174": "HEALTH & SAFETY ",
    "0175": "Environment ",
    "0176": "Ethics ",
    "0178": "Supply Chain Management ",
    "0179": "เคารพสิทธิมนุษยชนของแรงงานและปฏิบัติอย่างให้เกียรติ",
    "0180": "จัดสรรสภาพการทำงานที่ปลอดภัยต่อสุขภาพของพนักงาน",
    "0181": "ดำเนินธุรกิจอย่างมีความรับผิดชอบและเป็นมิตรต่อสิ่งแวดล้อม",
    "0182": "ดำเนินธุรกิจอย่างซื่อสัตย์ยึดถือหลักจริยธรรมเป็นมาตรฐานสูงสุด",
    "0183": "จัดการห่วงโซ่อุปทานอย่างมีความรับผิดชอบ เพื่อการพัฒนาอย่างยั่งยืน",
    "0184": "เสรีภาพในการเลือกงาน",
    "0185": "แรงงานเยาวชน",
    "0186": "ชั่วโมงการทำงาน",
    "0187": "ค่าจ้างสวัสดิการ",
    "0188": "การปฏิบัติอย่างมีมนุษยธรรม",
    "0189": "การไม่เลือกปฏิบัติ",
    "0190": "เสรีภาพในการสมาคม",
    "0191": "ความปลอดภัยในการทำงาน",
    "0192": "การเตรียมความพร้อมรับมือเหตุฉุกเฉิน",
    "0193": "การบาดเจ็บและความเจ็บป่วยจากการทำงาน",
    "0194": "สุขอนามัยอุตสาหกรรม",
    "0195": "งานที่ต้องใช้แรงงานทางกายภาพ",
    "0196": "การป้องกันอันตรายจากการใช้เครื่องจักรกล",
    "0197": "สุขอนามัย อาหาร และที่อยู่อาศัย",
    "0198": "ใบอนุญาตและรายงานด้านสิ่งแวดล้อม",
    "0199": "วัตถุอันตราย",
    "0200": "ขยะมูลฝอย",
    "0201": "การปล่อยมลภาวะทางอากาศ",
    "0202": "การจัดการน้ำ",
    "0203": "การใช่พลังงานและการปล่อยก๊าซเรือนกระจก",
    "0204": "ไม่มีการเอื้อผลประโยชน์ที่ไม่เหมาะสม",
    "0205": "การเปิดเผยข้อมูล",
    "0206": "ทรัพย์สินทางปัญญา",
    "0207": "ดำเนินธุรกิจ การโฆษณา และการแข่งขันอย่างเป็นธรรม",
    "0208": "การปกป้องผู้แจ้งเบาะแสและการไม่ตอบโต้",
    "0209": "ความเป็นส่วนตัว",
    "0210": "คำมั่นสัญญาของบริษัท",
    "0211": "ข้อจำกัดด้านวัสดุ",
    "0212": "การหาแหล่งแร่ธาตุโดยมีความรับผิดชอบ",
    "0213": "ความรับผิดอบต่อซัพพลายเออร์",
    "0214": "Safety Handbook",
    "0215": "Safety Handbook",
    "0216": "Safety is a top priority of the business",
    "0217": "Supplier Responsibility",
    "0218": "We support our suppliers to build a stronger competitive edge in order to establish a sound corporate ecosystem and a sustainable supply chain",
    "0219": "Samsung Electronics shall contribute to protecting the earth and improving people’s lives and be at the forefront of creating a sustainable society according to its business principle that prioritizes the environment, safety and health. The company shall put environmental protection and safety of all employees and clients first in its product development and manufacturing process",
    "0220": "Strengthening The Global EHS Management System",
    "0221": "It complies with domestic and international laws and agreements regarding the environment, energy, safety and health and faithfully applies and implements strict management standards in order to prevent environmental and safety accidents. It conducts training on the EHS management system so that all employees can understand and practice the EHS management system, and it discloses the EHS policy and business performance to internal and external interested parties.",
    "0222": "Putting The Principle of Responsibility in Practice across The Entire Cycle of Products",
    "0223": "It puts the principle of responsibility in action in purchase of raw materials, components and wrapping materials that have minimal environmental impacts and across the entire cycle of development, manufacturing, logistics, use and disposal of products in order to reduce use of resources and environmental pollution.",
    "0224": "Developing an Eco-Friendly Production Process",
    "0225": "It introduces optimal clean production technology to cut down chemical, energy and water use, encourage the reuse and recycling of waste materials and waste water and reduce pollutants.",
    "0226": "Implementing Climate Change Policy",
    "0227": "It views the crisis of climate change as a challenge for all to protect the earth and continuously strives to use more renewable energy, reduce greenhouse gas emissions and establish a low carbon ecosystem with the aim of combating climate change.",
    "0228": "Creating a Safe Workplace",
    "0229": "It creates a safety culture in which all employees engage to ensure a safe workplace and builds a zero-accident workplace by promoting employees’ health and eliminating safety risks for the purpose of prevention of severe accidents. <br> In addition, it protects employees and residents from external risks (e.g., natural disaster, fire and infectious disease) and puts the emergency response system in place so that business activity can continue.",
    "0230": "Forging Win-Win Partnership",
    "0231": "It maintains a cooperative relationship with partners in compliance with the code of conduct and forms an eco-friendly business partnership by sharing the EHS management system and technology. Furthermore, it contributes to boosting the local economy through communication with residents.",
    "0232": "Click to download Safety Handbook",
    "0233": "Procurement",
    "0234": "Procurement News",
    "0235": "Join with us",
    "0236": "Company name EN",
    "0237": "Business type",
    "0238": "Website",
    "0239": "Detail",
    "0240": "Attach file (Max 3 files)",
    "0241": "Contact name",
    "0242": "Contact number",
    "0243": "back",
    "0244": "Reference",
    "0245": "Join this",
    "0246": "Contact email",
    "0247": "Attach document",
    "0248": "We look forward to continuing our partnership.",
    "0249": "Period",
    "0250": "Copies of Military service certificate",
    "0251": "More Detail",
    "0252": "Company name TH",
    "0253": "Registered capital",
    "0254": "Registered date",
    "0255": "Registered No.",
    "0256": "Fiscal Year",
    "0257": "Company Type",
    "0258": "Province",
    "0259": "Customer",
    "0260": "Branch No",
    "0261": "Head Office",
    "0262": "Branch",
    "0263": "Apply Now",
    "0264": "News procurement",
    "0265": "Document",
    "0266": "Criteria",
    "0267": "Plan procurement",
    "0268": "Company profile",
    "0269": "Certificate of Business Registration (Not more than 3 months)",
    "0270": "VAT Registration (PP20)",
    "0271": "Copy of Bank account",
    "0272": "Confirmation letter from bank",
    "0273": "Passport/ ID card of authority",
    "0274": "Company registered capital. Details are as follows:",
    "0275": "Registered Capital 1,000,000 THB",
    "0276": "Safety Equipment/PPE (Glove/Safety Shoes/Mask)",
    "0277": "Training",
    "0278": "Furniture",
    "0279": "Souvenir/Premium Product",
    "0280": "Food (Cafeteria/Welfare shop/Chinese Dishes)",
    "0281": "Consumable (Stationery/Tree/Consumption) (Considered according to the nature of each trade.)",
    "0282": "The registered capital is more than the value of the work but not more than 3 million (the capital covers the price of the work but not more than 3 million)",
    "0283": "Construction",
    "0284": "Service EHS",
    "0285": "Tool & Equipment (variable capital by forecast cost)",
    "0286": "Service (Made to order/Rent",
    "0287": "Utility -Infrastructure & Utility (Electric, Water, Gas)",
    "0288": "Has been in business for at least 1 year.",
    "0289": "The company must not be a home office.",
    "0290": "The company must payment of tax according to the law.",
    "0291": "**Don't attach Important files. (Company Certificate, PP.20)",
    "0292": "555",
    "0293": "555",
    "0294": "555",
    "0295": "555",
    "0296": "555",
    "0297": "555",
    "0298": "555",
    "0299": "555",
    "0300": "555",
    "0301": "555",
    "0302": "555",
    "0303": "555",
    "0304": "555",
    "0305": "555",
    "0306": "555",
    "0307": "555",
    "0308": "555",
    "0309": "555",
    "0310": "555",
    "0311": "555",
    "0312": "555",
  }
}


export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: message
})
