const getDefaultState = () => {
    return {
        menuSystem: [],
        show: false,
    };
};
const state = getDefaultState();

const getters = {
    menuSystem: (state) => {
        return state.menuSystem;
    },
    show: (state) => {
        return state.show;
    },
};

const actions = {
    resetState({ commit }) {
        commit("resetState");
    },
    setMenu: ({ commit }, payload = []) => {
        commit("SET_APPROVAL", payload);
    },
    setShow: ({ commit }, payload = []) => {
        commit("SET_SHOW", payload);
    },
    setClickToggle: (payload) => {
        localStorage.setItem("showMenu", payload)
    },

};

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    SET_APPROVAL(state, payload) {
        state.menuSystem = payload;
    },
    SET_SHOW(state, payload) {
        state.show = payload;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};