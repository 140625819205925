<template>
  <div class="career relative">
    <v-overlay :value="overlay" opacity="0.4">
      <v-progress-circular :size="150" :width="7" indeterminate
        ><span style="color: #fff !important"
          >Loading...</span
        ></v-progress-circular
      >
    </v-overlay>
    <div class="banner-image banner-career">
      <div class="banner-text-box">
        <h1
          class="banner-h1 text-shadow2 text-uppercase"
          data-aos="fade-up"
          data-aos-delay="10"
        >
          {{ $t("0013") }}
        </h1>
        <h2
          class="banner-h2 text-shadow2"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          {{ $t("0018") }}
        </h2>
      </div>
    </div>
    <v-container>
      <v-row justify="center">
        <v-col xl="8" md="11" cols="12" style="z-index: 2">
          <h2 class="title-h2 liner ml-4 mt-3 mb-2">{{ $t("0147") }}</h2>
          <v-expansion-panels popout data-aos="fade-up" class="mt-5">
            <v-expansion-panel
              v-for="(careerData, index) in careerData"
              :key="index"
            >
              <v-expansion-panel-header class="title">{{
                careerData.title_eng
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="sub-title">{{ $t("0016") }}</div>
                <v-textarea
                  v-if="careerData.description_eng"
                  class="overwrite-v-textarea pa-0 ma-0"
                  v-model="careerData.description_eng"
                  readonly
                  auto-grow
                  outlined
                  rows="1"
                  row-height="1"
                ></v-textarea>


                <!-- <ul
                  v-for="(jobDe, index) in careerData.jobDe"
                  :key="'b' + index"
                >
                  <li>{{ jobDe.description_eng }}</li>
                </ul> -->
                <div class="sub-title">{{ $t("0015") }}</div>
                <v-textarea
                  v-if="careerData.qualification_eng"
                  class="overwrite-v-textarea pa-0 ma-0"
                  v-model="careerData.qualification_eng"
                  readonly
                  auto-grow
                  outlined
                  rows="1"
                  row-height="1"
                ></v-textarea>

                <!-- <p id="text-jobDe-th" class="detail-box"></p> -->
                <!-- <ul
                  v-for="(quali, index) in careerData.quali"
                  :key="'a' + index"
                >
                  <li>{{ quali.qualification_eng }}</li>
                </ul> -->

                <div class="sub-title">{{ $t("0017") }}</div>
                <div>
                  {{ careerData.exp }}
                  <span v-if="careerData.exp != 'ไม่ระบุ'">{{
                    $t("0054")
                  }}</span>
                </div>

                <v-btn
                  class="btn-apply"
                  @click="
                    $router
                      .push({
                        name: 'ApplyJob',
                        query: { jobID: careerData.idx },
                        params: { status: 1 },
                      })
                      .catch()
                  "
                  >{{ $t("0014") }}</v-btn
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col xl="8" md="11" cols="12" style="z-index: 2">
          <div class="text-center">
            <v-pagination
              v-model="currentPage"
              :length="lengthPage"
            ></v-pagination>
          </div>
        </v-col>

        <v-col xl="8" md="11" cols="12" style="z-index: 2">
          <div class="card-info mt-10">
            <p>{{ $t("0051") }}</p>
            <v-btn
              @click="
                $router
                  .push({
                    name: 'CareerOff',
                  })
                  .catch()
              "
              >{{ $t("0052") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import api from "@/services/api";
export default {
  async mounted() {
    await this.countView();
    await this.fetchData();
  },
  watch: {
    async currentPage() {
      await this.fetchData();
    },
  },
  methods: {
    async countView() {
      try {
        const resultRecruitCountView = await api.getRecruitCountView();
        if (resultRecruitCountView.status == 200) {
        }
      } catch (error) { }
    },
    async fetchData() {
      try {
        this.overlay = true;
        const data = {
          status: 1,
          currentPage: this.currentPage,
        };
        const result = await api.getCareerList(data);
        if (result.status == 200) {
          this.careerData = result.data.data.position;
          this.careerDataLength = result.data.data.position.length;

          this.lengthPage = result.data.data.allPage;
        }

      } catch (err) {
        this.$swal({
          icon: "error",
          title: "Sorry",
          text: err.message,
          timer: 4000,
        });
      } finally {
        this.overlay = false;
      }
    },
    paragraph(val) {
      // console.log("id " + id + val);
      if (val) {
        // const output = val.replace(/\n/g, "<br>");
        // document.getElementById(id).innerHTML = output;
        return val
        // return output
      }
    },
  },

  data() {
    return {
      lengthPage: 0,
      currentPage: 1,
      careerData: [],
      careerDataLength: "",
      jobData: [],
      overlay: false,
    };
  },
  name: "Career",
  created() {
    AOS.init();
  },
};
</script>
<style scoped>
.career {
  padding: 85px 0 100px 0;
  width: auto;
}
.card-info {
  background-image: linear-gradient(
    to left top,
    #34acda,
    #37b3e3,
    #3bbaec,
    #3ec2f6,
    #42c9ff
  );
  padding: 50px 20px;
  border-radius: 25px;
  text-align: center;
}
.card-info p {
  font-size: 1.375rem;
  color: #fff;
  width: 100%;
}
.title {
  color: #434343 !important;
}
.sub-title {
  font-family: SAMSUNGSHARPSANSBOLD;
  margin-top: 15px;
}
.btn-apply {
  margin-top: 20px;
}
/* .detail-box {
  padding: 10px 15px;
} */
@media screen and (max-width: 767px) {
  .career {
    padding: 60px 0 100px 0;
  }
}
</style>
