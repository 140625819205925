<template>
  <v-footer class="footer">
    Thai Samsung Electronics Co,.Ltd. All Right Reserved.
  </v-footer>
</template>

<script>
export default {}
</script>

<style scoped>
.footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 100%;
}
@media screen and (max-width: 575px) {
  .footer {
    font-size: 0.875rem;
  }
}
</style>
