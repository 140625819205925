<template>
     <section class="section-3">
      <div class="intro-wrap-3">
        <h4 class="text" data-aos="fade-down" data-aos-delay="100">
          {{ $t("0009") }}
        </h4>
        <div class="text" data-aos="fade-up" data-aos-delay="600">
          {{ $t("0010") }}
        </div>

        <v-btn
          class="btn-join"
          data-aos="fade-up"
          data-aos-delay="800"
          @click="$router.push({ name: 'Career' }).catch(() => {})"
          >{{ $t("0011") }}</v-btn
        >
      </div>
    </section>

</template>

<script>
export default {}
</script>

<style >
.section-3 {
  background-image: linear-gradient(
    to left top,
    #34acda,
    #37b3e3,
    #3bbaec,
    #3ec2f6,
    #42c9ff
  );
}
.intro-wrap-3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  max-width: 60%;
}
/* .intro-wrap-3 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
} */
.section-3 .text:nth-child(1) {
  font-size: 3rem;
  color: #fff;
}
.section-3 .text:nth-child(2) {
  margin-top: 20px;
  color: #fff;
  letter-spacing: 1px;
}
@media screen and (max-width: 1680px) {
  .intro-wrap-3 {
    max-width: 70%;
  }
}
@media screen and (max-width: 991px) {
  .section-3 {
    height: auto !important;
    min-height: 100vh;
  }
  .intro-wrap-3 {
    max-width: 80%;
    position: relative;
    z-index: 1;
    top: unset !important;
    left: unset !important;
    transform: unset !important;

    margin: 0 auto;
    padding: 100px 0 50px 0;
  }
  .section-3 .text:nth-child(1) {
    font-size: 2rem;
  }
}
@media screen and (max-width: 480px) {
  .section-3 .text:nth-child(1) {
    font-size: 1.5rem;
  }
}
</style>
