<template>
  <div class="career relative">
    <v-overlay :value="overlay" opacity="0.4">
      <v-progress-circular :size="150" :width="7" indeterminate
        ><span style="color: #fff !important"
          >Loading...</span
        ></v-progress-circular
      >
    </v-overlay>
    <div class="banner-image banner-career">
      <div class="banner-text-box">
        <h1
          class="banner-h1 text-shadow2 text-uppercase"
          data-aos="fade-up"
          data-aos-delay="10"
        >
          {{ $t("0013") }}
        </h1>
        <h2
          class="banner-h2 text-shadow2"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          {{ $t("0018") }}
        </h2>
      </div>
    </div>
    <v-container>
      <v-row justify="center">
        <v-col md="8" cols="12" style="z-index: 2">
          <v-btn
            class="btn-style-text mb-2"
            data-aos="fade-up"
            data-aos-delay="200"
            @click="$router.push({ name: 'Career' }).catch()"
          >
            {{ $t("0055") }}
          </v-btn>

          <h2 class="title-h2 liner ml-4 mt-3 mb-2">{{ $t("0053") }}</h2>

          <v-expansion-panels popout data-aos="fade-up" class="mt-5">
            <v-expansion-panel
              v-for="(careerData, index) in careerData"
              :key="index"
            >
              <v-expansion-panel-header class="title">{{
                careerData.title_eng
              }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="sub-title">{{ $t("0015") }}</div>
                <v-textarea
                  v-if="careerData.qualification_eng"
                  class="overwrite-v-textarea pa-0 ma-0"
                  v-model="careerData.qualification_eng"
                  readonly
                  auto-grow
                  outlined
                  rows="1"
                  row-height="1"
                ></v-textarea>
                <!-- {{ paragraph(careerData.qualification_eng,`quali-eng-${index}`) }} -->

                <!-- <ul
                  v-for="(quali, index) in careerData.quali"
                  :key="'a' + index"
                >
                  <li>{{ quali.qualification_eng }}</li>
                </ul> -->

                <div class="sub-title">{{ $t("0016") }}</div>
                <v-textarea
                  v-if="careerData.description_eng"
                  class="overwrite-v-textarea pa-0 ma-0"
                  v-model="careerData.description_eng"
                  readonly
                  auto-grow
                  outlined
                  rows="1"
                  row-height="1"
                ></v-textarea>

                <!-- <ul
                  v-for="(jobDe, index) in careerData.jobDe"
                  :key="'b' + index"
                >
                  <li>{{ jobDe.description_eng }}</li>
                </ul> -->

                <div class="sub-title">{{ $t("0017") }}</div>
                <ul>
                  <li>{{ careerData.exp }} years</li>
                </ul>

                <v-btn
                  class="btn-apply"
                  @click="
                    $router
                      .push({
                        name: 'ApplyJob',
                        query: { jobID: careerData.idx },
                        params: { status: 0 },
                      })
                      .catch()
                  "
                  >{{ $t("0014") }}</v-btn
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col md="8" cols="12" style="z-index: 2">
          <div class="text-center">
            <v-pagination
              v-model="currentPage"
              :length="lengthPage"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import api from "@/services/api";
export default {
  async mounted() {
    await this.fetchData();
  },
  watch: {
    async currentPage() {
      await this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      try {
        this.overlay = true;
        const data = {
          status: 0,
          currentPage: this.currentPage,
        };
        const result = await api.getCareerList(data);
        if (result.status == 200) {
          this.careerData = result.data.data.position;
          this.lengthPage = result.data.data.allPage;

          // this.paragraph(this.careerData.qualification_eng, "quali-eng");
          // this.paragraph(this.careerData.description_eng, "jobDe-eng");
        }
        const resultRecruitCountView = await api.getRecruitCountView();
        if (resultRecruitCountView.status == 200) {
        }
      } catch (err) {
        console.log(err.message);
        // this.$swal({
        //   icon: "error",
        //   title: "Sorry",
        //   text: "Somrthing went wrong",
        //   timer: 4000,
        // });
      } finally {
        this.overlay = false;
      }
    },
    // paragraph(val,id) {
    //   // console.log("id " + id + val);
    //   if (val) {
    //     const output = val.replace(/\n/g, "<br>");
    //     // document.getElementById(id).innerHTML = output;
    //     return output
    //   }
    // },
  },

  data() {
    return {
      lengthPage: 0,
      currentPage: 1,
      careerData: [],
      jobData: [],
      overlay: false,
    };
  },
  name: "CareerOff",
  created() {
    AOS.init();
  },
};
</script>
<style scoped>
.career {
  padding: 85px 0 100px 0;
  width: auto;
}
.title {
  color: #434343 !important;
}
.sub-title {
  font-family: SAMSUNGSHARPSANSBOLD;
  margin-top: 15px;
}
.btn-apply {
  margin-top: 20px;
}
.btn-style-text {
  background-color: #f9d0d05b !important;
  text-transform: lowercase;
  box-shadow: none;
  margin-top: 20px;
  margin-left: 15px;
}
.btn-style-text:hover {
  background-color: transparent !important;
}
/* .detail-box {
  padding: 10px 15px;
} */
@media screen and (max-width: 767px) {
  .career {
    padding: 60px 0 100px 0;
  }
}
</style>
