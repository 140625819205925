<template>
  <div id="about-us" class="about-us">
    <div class="banner-image banner-about-us">
      <div class="banner-text-box">
        <h1 class="banner-h1 text-shadow2 text-uppercase" data-aos="fade-up" data-aos-delay="10">{{ $t("0090") }}</h1>
        <h2 class="banner-h2 text-shadow2" data-aos="fade-up" data-aos-delay="300">{{ $t("0091") }}</h2>
      </div>
    </div>
    <v-container align="center">
      <v-row justify="center">
        <v-col xl="9" cols="11">
          <p data-aos="fade-up" data-aos-delay="400">{{ $t("0092") }}</p>
        </v-col>
        <v-col xl="10" cols="11" align="left">
          <div class="card-popup relative" data-aos="fade-up" data-aos-delay="100">
            <p>{{ $t("0093") }}</p>
            <p>{{ $t("0094") }}</p>
            <v-btn
              class="ma-2 btn-plus"
              fab
              color="white"
              elevation="0"
              @click="onclickOpenPopup(1)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <div id="popup2" class="card-popup relative" data-aos="fade-up" data-aos-delay="100">
            <p>{{ $t("0095") }}</p>
            <p>{{ $t("0096") }}</p>
            <v-btn
              class="ma-2 btn-plus"
              fab
              color="white"
              elevation="0"
              @click="onclickOpenPopup(2)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <div class="card-popup relative" data-aos="fade-up" data-aos-delay="100">
            <p>{{ $t("0097") }}</p>
            <p>{{ $t("0098") }}</p>
            <v-btn
              class="ma-2 btn-plus"
              fab
              color="white"
              elevation="0"
              @click="onclickOpenPopup(3)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="pa-12">
    <CsrSlide />
    </div>
    <v-dialog v-model="dialog" width="800px">
      <v-card class="body-card-popup">
        <v-card-title>
          <span class="header-card-popup liner" v-if="statusPopup == 1">{{
            $t("0093")
          }}</span>
          <span class="header-card-popup liner" v-if="statusPopup == 2">{{
            $t("0095")
          }}</span>
          <span class="header-card-popup liner" v-if="statusPopup == 3">{{
            $t("0097")
          }}</span>
        </v-card-title>
        <v-card-text v-if="statusPopup == 1">
          <h4 class="title-card-popup mt-5">{{ $t("0099") }}</h4>
          <h5 class="sub-title-card-popup mt-3">{{ $t("0100") }}</h5>
          <ul class="mt-4">
            <li class="mb-1">{{ $t("0101") }}</li>
            <li class="mb-1">{{ $t("0102") }}</li>
          </ul>

          <h4 class="title-card-popup mt-5">{{ $t("0103") }}</h4>
          <h5 class="sub-title-card-popup mt-3">{{ $t("0104") }}</h5>
          <ul class="mt-4">
            <li class="mb-1">{{ $t("0105") }}</li>
            <li class="mb-1">{{ $t("0106") }}</li>
          </ul>

          <h4 class="title-card-popup mt-5">{{ $t("0107") }}</h4>
          <h5 class="sub-title-card-popup mt-3">{{ $t("0108") }}</h5>
          <ul class="mt-4">
            <li class="mb-1">{{ $t("0109") }}</li>
            <li class="mb-1">{{ $t("0110") }}</li>
          </ul>
        </v-card-text>
        <v-card-text v-if="statusPopup == 2">
          <v-row class="mt-5">
            <v-col md="2" sm="3" cols="12">
              <div class="wrap-ico-about-us ico-people">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_people.png')"
                      ></v-img>
                    </div>
            </v-col>
            <v-col>
              <h4 class="title-card-popup">{{ $t("0059") }}</h4>
          <p class="mt-2">{{ $t("0064") }}</p>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col md="2" sm="3" cols="12">
              <div class="wrap-ico-about-us ico-excellence">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_excellance.png')"
                      ></v-img>
                    </div>
            </v-col>
            <v-col>
              <h4 class="title-card-popup">{{ $t("0060") }}</h4>
          <p class="mt-2">{{ $t("0065") }}</p>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col md="2" sm="3" cols="12">
              <div class="wrap-ico-about-us ico-change">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_Change.png')"
                      ></v-img>
                    </div>
            </v-col>
            <v-col>
              <h4 class="title-card-popup">{{ $t("0061") }}</h4>
          <p class="mt-2">{{ $t("0066") }}</p>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col md="2" sm="3" cols="12">
              <div class="wrap-ico-about-us ico-co-pro">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_Co_prosperity.png')"
                      ></v-img>
                    </div>
            </v-col>
            <v-col>
              <h4 class="title-card-popup">{{ $t("0062") }}</h4>
          <p class="mt-2">{{ $t("0067") }}</p>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col md="2" sm="3" cols="12">
              <div class="wrap-ico-about-us ico-integrity">
                      <v-img
                        class="value-ico"
                      :src="require('/src/assets/images/Icon_Intragity.png')"
                      ></v-img>
                    </div>
            </v-col>
            <v-col>
              <h4 class="title-card-popup">{{ $t("0063") }}</h4>
          <p class="mt-2">{{ $t("0068") }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="statusPopup == 3">
          <h4 class="title-card-popup mt-5">{{ $t("0111") }}</h4>
          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0112") }}</h5>
          <p class="mt-2">{{ $t("0113") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0114") }}</h5>
          <p class="mt-2">{{ $t("0115") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0116") }}</h5>
          <p class="mt-2">{{ $t("0117") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0118") }}</h5>
          <p class="mt-2">{{ $t("0119") }}</p>

         <h4 class="title-card-popup mt-5">{{ $t("0120") }}</h4>
          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0121") }}</h5>
          <p class="mt-2">{{ $t("0122") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0123") }}</h5>
          <p class="mt-2">{{ $t("0124") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0125") }}</h5>
          <p class="mt-2">{{ $t("0126") }}</p>

         <h4 class="title-card-popup mt-5">{{ $t("0127") }}</h4>
          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0128") }}</h5>
          <p class="mt-2">{{ $t("0129") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0130") }}</h5>
          <p class="mt-2">{{ $t("0131") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0132") }}</h5>
          <p class="mt-2">{{ $t("0133") }}</p>

          <h4 class="title-card-popup mt-5">{{ $t("0134") }}</h4>
          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0135") }}</h5>
          <p class="mt-2">{{ $t("0136") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0137") }}</h5>
          <p class="mt-2">{{ $t("0138") }}</p>

          <h4 class="title-card-popup mt-5">{{ $t("0139") }}</h4>
          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0140") }}</h5>
          <p class="mt-2">{{ $t("0141") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0142") }}</h5>
          <p class="mt-2">{{ $t("0143") }}</p>

          <h5 class="sub-title-card-popup sharpsansbold mt-3">{{ $t("0144") }}</h5>
          <p class="mt-2">{{ $t("0145") }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import CsrSlide from "./components/CsrSlide.vue";
import api from "@/services/api";
export default {
    components: {
    CsrSlide,
  },
  data() {
    return {
      dialog: false,
      statusPopup: "",
    };
  },
  name: "AboutUs",
  created() {
    AOS.init();
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    onclickOpenPopup(status) {
      (this.dialog = true), (this.statusPopup = status);
    },
    async fetchData() {
      try {
        const resultThaiSamsungCountView = await api.getThaiSamaungCountView();
        if (resultThaiSamsungCountView.status == 200) {
        }
      } catch (error) {
        console.log('error ' + error);
      }
    },
  },
};
</script>
<style>
.about-us {
  padding: 85px 0 100px 0;
}
.banner-text-box {
  position: absolute;
  top: 45%;
  left: 20%;
  transform: translateY(-50%);
}
.banner-h1 {
  font-size: 3.725rem;
  letter-spacing: 1px;
  font-family: SAMSUNGSHARPSANSBOLD;
  color: #1e1e1e;
}
.banner-h2 {
  font-size: 1.5rem;
  font-family: SAMSUNGSHARPSANSBOLD;
  letter-spacing: 1px;
  color: #1e1e1e;
  width: 100%;
  max-width: 500px;
}
.card-popup {
  background-image: linear-gradient(
    to left top,
    #34acda,
    #37b3e3,
    #3bbaec,
    #3ec2f6,
    #42c9ff
  );
  /* background-color: #000; */
  padding: 80px;
  border-radius: 30px;
  margin-bottom: 40px;
  height: 450px;
  background-position: right;
}
.card-popup p {
  color: #fff;
  width: 100%;
}
.card-popup p:nth-child(1) {
  font-size: 2rem;
  max-width: 40%;
}
.card-popup p:nth-child(2) {
  max-width: 55%;
}
.btn-plus {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.body-card-popup {
  padding: 50px;
}
.header-card-popup {
  font-size: 2rem;
}
.title-card-popup {
  font-size: 1.5rem;
  font-family: SAMSUNGSHARPSANSBOLD;
  letter-spacing: 1px;
  line-height: 1.2;
}
.sub-title-card-popup {
  font-size: 1.125rem;
}
/* .card-popup:nth-child(1){
 background-image: url("/src/assets/images/what-we-care.png");
 position: right;
}
.card-popup:nth-child(2){
background-image: url("/src/assets/images/what-we-ways.png");
}
.card-popup:nth-child(3){
background-image: url("/src/assets/images/what-we-care.png");
} */
.wrap-ico-about-us {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: relative;
}
@media screen and (max-width: 1264px) {
  .card-popup {
    padding: 50px;
  }
}
@media screen and (max-width: 991px) {
  .banner-text-box {
    left: 10%;
  }
  .banner-h1 {
    font-size: 2.875rem;
  }
  .banner-h2 {
    font-size: 1.375rem;
    /* max-width: 80%; */
  }
  .card-popup {
    height: 420px;
  }
  .card-popup p:nth-child(1) {
    max-width: 80%;
  }
  .card-popup p:nth-child(2) {
    max-width: 75%;
  }
}
@media screen and (max-width: 767px) {
  .about-us {
    padding: 70px 0 100px 0;
  }
  .banner-h1 {
    font-size: 2.5rem;
  }
  .banner-h2 {
    font-size: 1.375rem;
    /* max-width: 90%; */
  }
  .card-popup {
    padding: 30px;
  }
  .card-popup p:nth-child(1) {
    font-size: 1.875rem;
    max-width: 90%;
  }
  .card-popup p:nth-child(2) {
    max-width: 85%;
  }
  .body-card-popup {
    padding: 20px;
  }
}
@media screen and (max-width: 480px) {
  .card-popup {
    height: auto;
  }
}
</style>
