import Vue from "vue";
import Vuex from "vuex";
import login from "../store/modules/login";
import menu from "../store/modules/menu";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    menu,
  },
});
