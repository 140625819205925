<template>
  <div class="home">
    <Section1 />
    <Section2 />
    <Section5 />
    <Section4 />
    <div class="banner"></div>
    <Section3 />
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import Section1 from "./components/section-1.vue";
import Section2 from "./components/section-2.vue";
import Section4 from "./components/section-4.vue";
import Section5 from "./components/section-5.vue";
import Section3 from "./components/section-3.vue";
import api from "@/services/api";
export default {
  components: {
    Section1,
    Section2,
    Section4,
    Section5,
    Section3,
  },
  data() {
    return {
      model: 0,
      isMobile: false,
      lang: "en",
      languages: [
        { flag: "us", language: "en", title: "EN" },
        { flag: "th", language: "th", title: "TH" },
      ],
    };
  },
  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    await this.fetchData()
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
    async fetchData() {
      try {
        const resultThaiSamsungCountView = await api.getThaiSamaungCountView();
        if (resultThaiSamsungCountView.status == 200) {
        }
      } catch (error) {
        console.log('error ' + error);
      }
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  name: "Home",
  created() {
    AOS.init();
  },
};
</script>
<style>
html {
  scroll-behavior: smooth;
}
.triangle-right-top-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-right: 30vw solid #2fbbf0;
  border-bottom: 30vh solid transparent;
}
.triangle-left-bottom-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 30vh solid transparent;
  border-left: 30vw solid #2fbbf0;
  border-bottom: 0px solid transparent;
}
.triangle-left-top-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-left: 30vw solid #42c9ff;
  border-bottom: 20vh solid transparent;
}
.triangle-right-bottom-3 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 20vh solid transparent;
  border-right: 30vw solid #42c9ff;
  border-bottom: 0px solid transparent;
}
.triangle-right-top-3 {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-right: 30vw solid #2fbbf0;
  border-bottom: 35vh solid transparent;
}
.triangle-left-bottom-4 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 35vh solid transparent;
  border-left: 35vw solid #2fbbf0;
  border-bottom: 0px solid transparent;
}
.triangle-left-bottom-5 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 50vh solid transparent;
  border-left: 80vw solid #2fbbf0;
  border-bottom: 0px solid transparent;
}
.triangle-right-bottom-5 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 45vh solid transparent;
  border-right: 30vw solid #42c9ff;
  border-bottom: 0px solid transparent;
}
.triangle-top-left-6 {
  position: absolute;
  bottom: 65%;
  left: 0;
  width: 0;
  height: 0;
  border-top: 30vh solid transparent;
  border-left: 18vw solid #2fbbf0;
  border-bottom: 0 solid transparent;
  transform: translateY(-50%);
}
.triangle-bottom-left-6 {
  position: absolute;
  top: 35%;
  left: 0;
  width: 0;
  height: 0;
  border-top: 0vh solid transparent;
  border-left: 18vw solid #42c9ff;
  border-bottom: 30vh solid transparent;
  transform: translateY(-50%);
}
.relative {
  position: relative;
}
.home {
  width: auto;
  position: relative;
}
section {
  height: 110vh;
  position: relative;
}
.text-header {
  font-family: SAMSUNGSHARPSANSBOLD;
  font-size: 2.375rem;
  margin-bottom: 5px;
  letter-spacing: 2px;
  line-height: 1.2;
}
.text-header-section {
  background-color: #42c9ff;
  letter-spacing: 2px;
  font-size: 1.875rem;
  width: fit-content;
  padding: 10px 20px;
  color: #fff;
  position: relative;
  z-index: 1;
}
.liner {
  border-left: 3px solid #42c9ff;
  margin-top: 10px;
  padding: 5px 10px;
}
.icon-manufucturing {
  height: auto;
  margin: 0 auto;
  max-width: 30px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: sub;
}
.text-shadow {
  text-shadow: 0px 0px 10px white;
}
.text-shadow2 {
  text-shadow: 0px 0px 8px rgb(220 220 220 / 26%);
}
.text-wrap {
  padding: 40px 100px 30px 30px;
}
.text-title {
  font-size: 1.5rem;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 15px;
  border-left: 2px solid #fff;
  font-family: "SAMSUNGSHARPSANSBOLD";
  padding-left: 10px;
}
.text-wrap p {
  letter-spacing: 0.5px;
  color: #fff;
  padding-right: 30px;
}
.title-h1 {
  font-size: 1.875rem;
  text-align: center;
  letter-spacing: 1px;
}
.strong {
  text-align: center;
  letter-spacing: 2px;
  border-top: 3px solid rgba(55, 152, 249, 0.5);
  width: fit-content;
  margin: 0 auto;
  display: block;
}
.banner {
  background-image: url("./../assets/images/banner3.JPG");
  min-height: 300px;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
@supports (-webkit-touch-callout: none) {
  .banner {
    background-image: url("./../assets/images/banner3.JPG");
    min-height: 300px;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
}
.banner-image {
  min-height: 600px;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 70px;
}
.banner-about-us {
  background-image: url("./../assets/images/bg-banner.png");
}
.banner-career {
  background-image: url("/src/assets/images/bg-banner-2.png");
}
.banner-supplier-res {
  background-image: url("/src/assets/images/bg-banner-3.png");
}
.banner-safety-hand {
  background-image: url("/src/assets/images/bg-banner-4.png");
}
.banner-procurement {
  background-image: url("/src/assets/images/bg-banner-5.png");
}
.btn-join {
  margin-top: 20px;
}
.title-h2 {
  font-size: 1.375rem;
}
@media screen and (max-width: 1680px) {
  .text-wrap {
    padding: 40px 50px 30px 30px;
  }
  .text-wrap p {
    letter-spacing: 0.5px;
    color: #fff;
    padding-right: 30px;
  }
}
@media screen and (max-width: 991px) {
  .text-header {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 767px) {
  .text-header {
    font-size: 1.875rem;
  }
  .text-header-section {
    font-size: 1.5rem;
  }
  .text-title {
    font-size: 1.375rem;
  }
  .banner {
    min-height: 250px;
  }
}
@media screen and (max-width: 575px) {
  .triangle-top-left-6 {
  border-top: 30vh solid transparent;
  border-left: 30vw solid #2fbbf0;
}
.triangle-bottom-left-6 {
  border-left: 30vw solid #42c9ff;
  border-bottom: 30vh solid transparent;
}
}
@media screen and (max-width: 480px) {
  .banner-image {
    min-height: 400px;
    margin-bottom: 50px;
  }
  .title-h1 {
    font-size: 1.5rem;
  }
  @supports (-webkit-touch-callout: none) {
    .banner {
      background-image: url("./../assets/images/banner3.JPG");
      min-height: 300px;
      background-attachment: initial;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
    }
  }
}
</style>
