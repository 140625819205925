<template>
  <section id="section5" class="section-5">
    <div class="intro-wrap-5" style="z-index: 2">
      <div class="text-header-section" data-aos="fade-up" data-aos-delay="5">
        What We Care
      </div>
      <div class="liner mt-2" data-aos="fade-up" data-aos-delay="20">
        {{ $t("0079") }}
      </div>
      <CsrSlide />
    </div>
    <div class="triangle-left-top-2"></div>
    <div class="triangle-right-bottom-3"></div>
  </section>
</template>
<script>
import CsrSlide from "./CsrSlide.vue";
export default {
  components: {
    CsrSlide,
  },
  data() {
    return {
      window: 0,
    };
  },
};
</script>

<style>
.intro-wrap-5 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  max-width: 60%;
}
@media screen and (max-width: 1680px) {
  .intro-wrap-5 {
    max-width: 70%;
  }
}
@media screen and (max-width: 991px) {
  .section-5 {
    height: auto !important;
    min-height: 110vh;
  }
  .intro-wrap-5 {
    max-width: 80%;
    position: relative;
    z-index: 1;
    top: unset !important;
    left: unset !important;
    transform: unset !important;

    margin: 0 auto;
    padding: 70px 0 70px 0;
  }
}
</style>
