<template>
  <v-dialog v-model="dialog" width="800px">
    <v-card class="body-card-popup">
      <v-card-title>
        <span class="header-card-popup liner">
          {{ $t("0148") }}
        </span>
      </v-card-title>
      <v-card-text>
        <h4 class="title-card-popup mt-5">นโยบายการเก็บรักษาข้อมูลส่วนตัว</h4>
        <p class="mt-2">
          Samsung Electronics Co., Ltd. (เรียกว่า “Samsung Electronics”,
          “Samsung” หรือ “เรา” ในนโยบายนี้) ซึ่งมีสำนักงานใหญ่อยู่ในเกาหลีใต้
          ทราบดีว่าความเป็นส่วนตัวมีความสำคัญต่อผู้ใช้บริการของเราเพียงใด
          เราต้องการทำให้แน่ใจว่าคุณเข้าใจวิธีที่เราเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของคุณบนอุปกรณ์และบริการทั้งหมดของ
          Samsung นับตั้งแต่โทรศัพท์มือถือและแท็บเล็ตไปจนถึงโทรทัศน์
          เครื่องใช้ไฟฟ้าในบ้าน บริการออนไลน์ เว็บไซต์ แอปพลิเคชัน การสำรวจ
          และบริการอื่นของ Samsung ที่อ้างถึงหรือเชื่อมโยงถึงนโยบายนี้
          (เรียกรวมกันว่า “บริการ” ของเรา)
        </p>
        <p class="mt-2">
          แม้ว่านโยบายความเป็นส่วนตัวนี้จะมีผลบังคับใช้กับบริการทั้งหมดของเรา
          แต่เรายังจะให้ข้อมูลเพิ่มเติมเกี่ยวกับความเป็นส่วนตัวที่เกี่ยวข้องกับบริการเฉพาะตามที่จำเป็น
        </p>

        <h4 class="title-card-popup mt-5">ภาพรวมของข้อมูลส่วนบุคคล</h4>
        <p class="mt-2">
          เราเก็บรวบรวมข้อมูลส่วนบุคคลที่คุณมอบให้โดยตรง
          ข้อมูลเกี่ยวกับวิธีที่คุณใช้บริการของเรา
          และข้อมูลจากแหล่งข้อมูลของบุคคลที่สาม
          เราใช้ข้อมูลนี้เพื่อนำเสนอบริการต่อคุณ
          ทำความเข้าใจว่าคุณใช้บริการของเราอย่างไร
          เพื่อให้เราสามารถปรับปรุงและปรับประสบการณ์ของคุณให้เป็นแบบเฉพาะบุคคล
          และเพื่อพัฒนาแอปพลิเคชัน เทคโนโลยี
          และเนื้อหาที่เกี่ยวข้องมากที่สุดสำหรับผู้ใช้บริการของเรา นอกจากนี้
          เรายังใช้ข้อมูลส่วนบุคคลเพื่อนำเสนอบริการ
          ในแบบเฉพาะบุคคลที่เหมาะกับความสนใจของคุณตามกฎหมายที่มีผลบังคับใช้
        </p>
        <p class="mt-2">
          นอกจากนั้น
          เรายังอาจขอความยินยอมจากคุณเพื่อเก็บรวบรวมข้อมูลที่ไม่ได้ระบุไว้ในนโยบายความเป็นส่วนตัวด้วย
        </p>

        <h4 class="title-card-popup mt-5">การใช้และการแบ่งปันข้อมูล</h4>
        <p class="mt-2">
          เราใช้ข้อมูลที่เราเก็บรวบรวมเพื่อดำเนินการต่าง ๆ เช่น
        </p>
        <ul>
          <li class="mb-1">กระทำการอื่นที่คุณให้ความยินยอม</li>
          <li class="mb-1">ให้บริการที่คุณร้องขอ</li>
        </ul>

        <h4 class="title-card-popup mt-5">เราเก็บรวบรวมข้อมูลอะไรบ้าง</h4>
        <p class="mt-2">Samsung ใช้หลายวิธีในการเก็บรวบรวมข้อมูลจากคุณ</p>

        <h4 class="title-card-popup mt-5">ข้อมูลที่คุณให้ไว้โดยตรง</h4>
        <p class="mt-2">
          หากคุณสนใจสมัครงานร่วมกับเรา เราอาจจะขอทราบข้อมูลของคุณ เช่น
          ชื่อนามสกุล ชื่อเล่น วันเดือนปีเกิด ที่อยู่อีเมล เบอร์โทรศัพท์
          เลขประจำตัวประชาชน ข้อมูลทางการศึกษา และเอกสารที่ใช้ประกอบการสมัครงาน
        </p>

        <h4 class="title-card-popup mt-5">
          ข้อมูลที่ได้รับจากการที่คุณใช้บริการ
        </h4>
        <p class="mt-2">
          นอกเหนือจากข้อมูลที่คุณมอบให้
          เราอาจเก็บรวบรวมข้อมูลของคุณเกี่ยวกับการที่คุณใช้บริการของเราผ่านทางซอฟต์แวร์ที่อยู่บนอุปกรณ์ของคุณหรือด้วยวิธีการอื่น
          ๆ ข้อมูลนี้รวมไปถึง:
        </p>
        <ul>
          <li class="mb-1">
            ข้อมูลอุปกรณ์: ที่อยู่ MAC, ที่อยู่ IP, เวอร์ชันระบบปฏิบัติการ,
            เวอร์ชันเบราว์เซอร์
          </li>
          <li class="mb-1">
            ข้อมูลบันทึกการใช้งาน: ข้อมูลเกี่ยวกับการวิเคราะห์ ข้อมูลทางเทคนิค
            ข้อผิดพลาด และการใช้งาน เช่น ระยะเวลาในการใช้บริการของคุณ
            บริการที่ติดตั้งบนอุปกรณ์ของคุณ
            ข้อความค้นหาเมื่อคุณป้อนข้อความค้นหาในอุปกรณ์ของคุณโดยเชื่อมโยงกับบริการเฉพาะของ
            Samsung ข้อมูลใด ๆ ที่จัดเก็บไว้บนอุปกรณ์ของคุณ
            และรายงานข้อผิดพลาดและบันทึกการถ่ายโอนข้อมูล
          </li>
          <li class="mb-1">
            ข้อมูลตำแหน่งที่อยู่: ข้อมูลที่เกี่ยวกับจุดเชื่อมต่อ Wi-Fi
            ในบริเวณใกล้เคียง เสาโทรศัพท์ และถ้าคุณให้ความยินยอมแยกต่างหาก
            สัญญาณ GPS
            ของอุปกรณ์ของคุณอาจถูกส่งไปยังเราเมื่อคุณใช้บริการบางอย่าง นอกจากนี้
            สำหรับบางบริการ
            เรายังเก็บรวบรวมข้อมูลรหัสไปรษณีย์และตำแหน่งที่อนุมานโดยใช้ที่อยู่
            IP ที่อาจถูกส่งถึงเราเมื่อคุณใช้บริการดังกล่าว
          </li>
        </ul>

        <h4 class="title-card-popup mt-5">ข้อมูลอื่น ๆ ที่เราเก็บรวบรวม</h4>
        <p class="mt-2">
          เรายังเก็บรวบรวมข้อมูลอื่น ๆ ที่เกี่ยวกับคุณ อุปกรณ์ของคุณ
          หรือการใช้บริการที่คุณยินยอมที่จะส่งให้กับเรา
          หรือเราได้รับความยินยอมจากคุณตามกฎหมายที่มีผลบังคับใช้
        </p>

        <h4 class="title-card-popup mt-5">เครื่องมือวิเคราะห์ของบุคคลที่สาม</h4>
        <p class="mt-2">
          เราอาจใช้บริการวิเคราะห์ความปลอดภัยของบุคคลที่สามบนบริการของเรา เช่น
          บริการของ Google reCAPTCHA ผู้ให้บริการเครื่องมือวิเคราะห์ความเสี่ยง
          และป้องกันกิจกรรมที่มีความเสี่ยง หรือเป็นอันตรายบนเว็บไซต์
          ข้อมูลที่เราได้รับอาจถูกเปิดเผยต่อหรือถูกเก็บรวบรวมโดยตรงโดยผู้ให้บริการเหล่านี้และบุคคลที่สามอื่นๆ
          ที่เกี่ยวข้องซึ่งใช้ข้อมูลนี้เพื่อประเมินการใช้บริการ ช่วยให้บริการ
          และวินิจฉัยปัญหาทางเทคนิค เป็นต้น หากต้องการดูข้อมูลเพิ่มเติมเกี่ยวกับ
          Google reCAPTCHA โปรดไปที่

          <a href="https://policies.google.com" target="_blank" class="under-line text-link">https://policies.google.com</a> และ
          <a href="https://policies.google.com/technologies/partner-sites" target="_blank" class="under-line text-link">>https://policies.google.com/technologies/partner-sites</a
          >
        </p>

        <h4 class="title-card-popup mt-5">เราใช้ข้อมูลของคุณอย่างไร</h4>
        <ul>
          <p class="mt-2">
            เราใช้ข้อมูลที่เราเก็บรวบรวมเพื่อวัตถุประสงค์ดังต่อไปนี้:
          </p>
          <li class="mb-1">เพื่อจัดหาบริการและคุณลักษณะการใช้งานตามที่คุณขอ</li>
          <li class="mb-1">เพื่อปฏิบัติตามกฎหมายและกระบวนการทางกฎหมาย</li>
          <li class="mb-1">
            เพื่อคุ้มครองสิทธิและผลประโยชน์ตามกฎหมาย ทรัพย์สิน
            หรือความปลอดภัยของ Samsung
          </li>
          <li class="mb-1">
            เพื่อให้การคุ้มครอง ระบุ และป้องกันการฉ้อโกง
            และการกระทำที่เป็นอาชญากรรมอื่น ๆ การร้องเรียน และความ รับผิดอื่น ๆ
          </li>
          <li class="mb-1">
            เพื่อปฏิบัติตามข้อกำหนดตามกฎหมายที่นำมาใช้บังคับ
            มาตรฐานอุตสาหกรรมที่เกี่ยวข้อง และนโยบายของเรา
            รวมถึงนโยบายความเป็นส่วนตัวของ Samsung
          </li>
          <li class="mb-1">
            โดยความยินยอมของคุณ
            สามารถเพิกถอนความยินยอมนั้นได้โดยทำตามขั้นตอนการยกเลิกการฝากประวัติที่ระบุอยู่บนเว็บไซต์นั้น
          </li>
        </ul>

        <h4 class="title-card-popup mt-5">เราเปิดเผยข้อมูลของคุณต่อใคร</h4>
        <p class="mt-2">
          เราจะเปิดเผยข้อมูลของคุณเป็นการภายใน
          ให้แก่เฉพาะผู้ที่จำเป็นต้องใช้ข้อมูลดังกล่าวเพื่อให้บริการหรือเพื่อช่วยคุณดำเนินการตามคำขอเท่านั้น
          โดยอยู่ในขอบเขตตามวัตถุประสงค์ที่จำเป็นต่อการให้บริการของเราเท่านั้น
        </p>
        <ul>
          <li class="mb-1">
            ผู้ให้บริการ: ผู้ให้บริการที่มีหน้าที่ในการสรรหาพนักงานของบริษัท
            และหัวหน้าแผนกซึ่งรวมไปถึงหัวหน้างานในตำแหน่งที่ท่านให้ความสนใจในการสมัครงาน
            ผู้ให้บริการเหล่านี้มีพันธกิจในการปกป้องข้อมูลของคุณด้วยเช่นกัน
          </li>
          <li class="mb-1">
            บุคคลอื่น ๆ ตามที่กฎหมายกำหนดหรือตามความจำเป็นเพื่อปกป้องบริการ:
            เช่น มีความจำเป็นตามที่กฎหมายกำหนด กระบวนการทางกฎหมาย
            หรือคำสั่งศาลจากหน่วยงานภาครัฐในการเปิดเผยข้อมูลของคุณ
            บุคคลเหล่านี้ยังสามารถขอข้อมูลของคุณจากเราเพื่อวัตถุประสงค์ในการบังคับใช้กฎหมาย
            ความมั่นคงของประเทศ การต่อต้านการก่อการร้าย หรือปัญหาอื่น ๆ
            ที่เกี่ยวข้องกับความมั่นคงของประเทศ
          </li>
          <li class="mb-1">
            บุคคลอื่น ๆ ที่เกี่ยวข้องกับการทำธุรกรรมของบริษัท:
            เราอาจเปิดเผยข้อมูลของคุณให้กับบุคคลที่สามซึ่งเป็นส่วนหนึ่งของการควบรวมกิจการหรือการโอนกิจการ
            หรือในกรณีที่มีการล้มละลาย
          </li>
          <li class="mb-1">บุคคลอื่น ๆ ตามความยินยอมหรือตามคำแนะนำ</li>
        </ul>

        <h4 class="title-card-popup mt-5">
          เราทำอย่างไรเพื่อให้ข้อมูลของคุณมีความปลอดภัย
        </h4>
        <p class="mt-2">
          เราให้ความสำคัญกับการปกป้องข้อมูล
          เราได้กำหนดมาตรการการป้องกันทั้งทางกายภาพและทางเทคนิคเพื่อให้ข้อมูลที่เราเก็บรวบรวมมีความปลอดภัยตลอดเวลา
          อย่างไรก็ตาม
          โปรดทราบว่าถึงแม้ว่าเราจะใช้ขั้นตอนที่เหมาะสมเพื่อปกป้องข้อมูลของคุณแล้ว
          แต่ก็ไม่มีเว็บไซต์ การส่งข้อมูลทางอินเทอร์เน็ต ระบบคอมพิวเตอร์
          หรือการเชื่อมต่อไร้สายใดที่จะปลอดภัยอย่างสมบูรณ์
        </p>

        <h4 class="title-card-popup mt-5">สิทธิ์ในข้อมูลที่คุณได้รับ</h4>
        <p class="mt-2">
          ข้อมูลส่วนบุคคลของคุณเป็นของคุณ
          คุณมีสิทธิ์ที่สามารถขอให้เราบอกรายละเอียดเกี่ยวกับข้อมูลที่เราได้เก็บรวบรวม
          ลบออก หรือใช้สิทธิ์อื่นใดที่คุณอาจจะได้รับ ตามกฎหมายที่มีผลบังคับใช้
          อย่างไรก็ตาม
          การขอลบข้อมูลส่วนบุคคลอาจทำให้คุณสูญเสียการเข้าถึงบริการที่เรานำเสนอ
          เราจะไม่ลบข้อมูลที่กฎหมายกำหนดให้ต้องเก็บรักษา
          หากต้องการยื่นคำร้องเกี่ยวกับสิทธิ์ของคุณหรือสอบถามข้อมูล
          โปรดติดต่อเรา โปรดดูที่<strong>ส่วนติดต่อเรา</strong>
        </p>

        <h4 class="title-card-popup mt-5">
          เราเก็บรักษาข้อมูลของคุณไว้นานเท่าใด
        </h4>
        <p class="mt-2">
          เราจะไม่เก็บข้อมูลส่วนบุคคลของคุณไว้นานเกินความจำเป็นตามวัตถุประสงค์ของการเก็บรวบรวมข้อมูลนั้น
          เว้นแต่กฎหมายที่มีผลบังคับใช้จะกำหนดไว้เป็นอย่างอื่น
          ซึ่งหมายถึงข้อมูลจะต้อง ถูกทำลายหรือลบออกจากระบบของเรา
          เมื่อไม่มีความจำเป็นอีกต่อไป
        </p>

        <h4 class="title-card-popup mt-5">วิธีติดต่อเรา</h4>
        <p class="mt-2">
          313 หมู่ 1 สวนอุตสาหกรรมศรีราชา ถนนสุขาภิบาล 8 ตำบลบึง อำเภอศรีราชา
          จังหวัดชลบุรี 20230
        </p>
        <p class="mt-2">โทรศัพท์ 0-3832-0777</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "update:ChangePage",
  },
  props: ["value"],
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:ChangePage", val);
      },
    },
  },
};
</script>

<style></style>
