<template>
  <div>
    <v-app-bar-nav-icon
      @click.stop="drawer = !drawer"
      class="nav-icon"
    ></v-app-bar-nav-icon>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
      class="nav-drawer"
    >
      <v-list nav dense>
        <v-list-item-group class="list-group" v-model="group">
          <v-list-item @click="$router.push({ name: 'Home' }).catch(() => {})">
            <v-list-item-title style="font-size: 16px">
              {{ $t("0001") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="$router.push({ name: 'AboutUs' }).catch(() => {})"
          >
            <v-list-item-title style="font-size: 16px">
              {{ $t("0002") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="$router.push({ name: 'Career' }).catch(() => {})"
          >
            <v-list-item-title style="font-size: 16px">
              {{ $t("0004") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="
              $router.push({ name: 'SupplierResponsibility' }).catch(() => {})
            "
          >
            <v-list-item-title style="font-size: 16px">
              {{ $t("0163") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="$router.push({ name: 'SafetyHandbbok' }).catch(() => {})"
          >
            <v-list-item-title style="font-size: 16px">
              {{ $t("0214") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$router.push({ name: 'Procurement' }).catch(() => {})"
          >
            <v-list-item-title style="font-size: 16px">
              {{ $t("0233") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="$router.push({ name: 'ContactUs' }).catch(() => {})"
          >
            <v-list-item-title style="font-size: 16px">
              {{ $t("0003") }}
            </v-list-item-title>
          </v-list-item>

          <!-- <v-list-item @click="$router.push({ name: 'BusinessContact' }).catch(() => {})">
            <v-list-item-title
              style="font-size: 16px"
            >
              {{ $t("0146") }}
            </v-list-item-title>
          </v-list-item> -->

          <v-select
            hide-details
            class="mb-select"
            v-model="lang"
            :items="languages"
            item-text="title"
            item-value="language"
            @change="changeLocale"
            outlined
            dense
          ></v-select>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import i18n from "@/i18n";
export default {
  data: () => ({
    drawer: false,
    group: null,
    lang: "en",
    languages: [
      { flag: "us", language: "en", title: "EN" },
      { flag: "th", language: "th", title: "TH" },
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    i18n.locale = this.lang;
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
      this.$emit('changeLocale', locale)
    },
  },
};
</script>

<style scoped>
.nav-icon {
  z-index: 3;
  top: 21px;
  left: 21px;
  position: fixed;
  cursor: pointer;
}
.nav-drawer {
  position: fixed;
}
.list-group {
  height: 100vh;
  padding-top: 30px;
}
.v-list--nav {
  padding: 0px !important;
}
.v-list-item {
  padding: 5px;
  text-align: center;
}
.v-list-item a {
  text-decoration: none;
  color: #434343;
}
.mb-select {
  width: 80px;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (min-width: 1501px) {
  .nav-icon {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .nav-icon {
    top: 12px;
    left: 12px;
  }
}
</style>
