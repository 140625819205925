import axios from "axios";
import { apiUrl } from "@/services/constants";

// import router from "@/router";
// import Swal from "sweetalert2";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;
axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": apiUrl,
      "Access-Control-Allow-Credentials": true,
    };
    config.withCredentials = true;
    config.url = apiUrl + "/" + config.url;
  }
  config.timeout = 50000;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      // if (error.response.data.httpCode == 401) {
      //   Swal.fire({
      //     title: "Error!",
      //     text: error.response.data.message,
      //     icon: "error",
      //     confirmButtonText: "OK",
      //   });
      //   router.push({ name: "Login" }).catch(() => {});
      // } else if (error.response.data.httpCode == 403) {
      //   Swal.fire({
      //     title: "Error!",
      //     text: error.response.data.message,
      //     icon: "error",
      //     confirmButtonText: "OK",
      //   });
      //   router.push({ name: "Home" }).catch(() => {});
      // }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);

export default axios;
