import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Career from "../views/Career.vue";
import CareerOff from "../views/CareerOff.vue";
import ApplyJob from "../views/ApplyJob.vue";
import AboutUs from "../views/AboutUs.vue";
import ContactUs from "../views/ContactUs.vue";
import BusinessContact from "../views/BusinessContact.vue";
import AttachDocs from "../views/AttachDocs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: { name: "Home" },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/career",
    name: "Career",
    component: Career,
  },
  {
    path: "/career/career-off",
    name: "CareerOff",
    component: CareerOff,
  },
  {
    path: "/career/apply-job/:status",
    name: "ApplyJob",
    component: ApplyJob,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/business-contact",
    name: "BusinessContact",
    component: BusinessContact,
  },
  {
    path: "/attach-documents/:idx",
    name: "AttachDocs",
    component: AttachDocs,
  },
  {
    path: "/confirm-interest/:idx",
    name: "confirm-interest",
    component: () =>
      import(
        /* webpackChunkName: "confirm-interest" */ "../views/InterestPage.vue"
      ),
  },
  {
    path: "/supplier-responsibility",
    name: "SupplierResponsibility",
    component: () =>
      import(
        /* webpackChunkName: "supplier-responsibility" */ "../views/SupplierResponsibility.vue"
      ),
  },
  {
    path: "/safety-handbbok",
    name: "SafetyHandbbok",
    component: () =>
      import(
        /* webpackChunkName: "safety-handbbok" */ "../views/SafetyHandbbok.vue"
      ),
  },
  {
    path: "/procurement",
    name: "Procurement",
    component: () =>
      import(
        /* webpackChunkName: "procurement" */ "../views/Procurement.vue"
      ),
  },
  {
    path: "/procurement-copy",
    name: "ProcurementCopy",
    component: () =>
      import(
        /* webpackChunkName: "procurement" */ "../views/Procurement copy.vue"
      ),
  },
  {
    path: "/procurement/procurement-post",
    name: "ProcurementPost",
    component: () =>
      import(
        /* webpackChunkName: "procurement-post" */ "../views/ProcurementPost.vue"
      ),
  },
  {
    path: "/procurement/apply-procurement",
    name: "ApplyProcurement",
    component: () =>
      import(
        /* webpackChunkName: "apply-procurement" */ "../views/ApplyProcurement.vue"
      ),
  },
  {
    path: "/error-page",
    name: "ErrorPage",
    component: () =>
      import(
        /* webpackChunkName: "404" */ "../views/ErrorPage.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
