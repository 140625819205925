<template>
    <v-overlay :value="false" opacity="0">
      <v-progress-circular :size="150" :width="7" color="black" indeterminate
        >Loading...</v-progress-circular
      >
    </v-overlay>
</template>
<script>
export default {
    name:"Loading",
    props:["show"]
}
</script>