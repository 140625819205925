<template>
  <div class="apply-job relative">
    <v-overlay :value="overlay" opacity="0.4">
      <v-progress-circular :size="150" :width="7" indeterminate
        ><span style="color: #fff !important"
          >Loading...</span
        ></v-progress-circular
      >
    </v-overlay>
    <v-container>
      <v-row justify="center">
        <v-col md="9" cols="11" align="center" style="z-index: 2">
          <h1 data-aos="fade-down">{{ $t("0019") }}</h1>

          <v-form
            class="form"
            ref="form"
            v-model="valid"
            lazy-validation
            data-aos="fade-up"
          >
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.position"
                  :items="itemsPosi"
                  item-text="p_name_eng"
                  item-value="idx"
                  :label="`${$t('0020')}`"
                  type="text"
                  maxLength="100"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="form.title_name"
                  :items="itemsTitleName"
                  item-text="title_eng"
                  item-value="idx"
                  :rules="titleNameRules"
                  :label="`${$t('0021')}`"
                  required
                  type="text"
                  maxLength="100"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="form.name_eng"
                  :rules="nameENRules"
                  :label="`${$t('0022')}`"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="form.name_th"
                  :rules="nameTHRules"
                  :label="`${$t('0023')}`"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="id_no"
                  :rules="idNoRules"
                  :label="`${$t('0027')}`"
                  :placeholder="`${$t('0028')}`"
                  :maxlength="idNoMax"
                  :v-text="idNoMax - form.id_no.length"
                  required
                  counter="13"
                  type="text"
                  class="hide-arrow"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="tel"
                  :rules="phoneNumbRules"
                  :label="`${$t('0024')}`"
                  :maxlength="phoneNumberMax"
                  :v-text="phoneNumberMax - form.tel.length"
                  counter="10"
                  required
                  type="text"
                  class="hide-arrow"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  required
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.birthday"
                      :rules="birthDateRules"
                      label="Birthday date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.birthday"
                    :active-picker.sync="activePicker"
                    :max="maxBirthdate"
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                  <!-- <v-date-picker
                    v-model="form.birthday"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker> -->
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="form.edu_level"
                  :items="itemsEduLv"
                  item-text="name_eng"
                  item-value="idx"
                  :rules="levelRules"
                  :label="`${$t('0029')}`"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="form.edu_major"
                  :items="itemsEduMj"
                  item-text="major_name_eng"
                  return-object
                  :rules="majorRules"
                  :label="`${$t('0030')}`"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                v-if="form.edu_major.major_name_eng == 'Other'"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <v-text-field
                  v-model="form.other"
                  :rules="otherMajorRules"
                  :label="`${$t('0056')}`"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="form.years_exp"
                  :items="itemsYear"
                  :rules="[
                    (v) =>
                      !!v || 'Years of Professional Experience is required',
                  ]"
                  :label="`${$t('0031')}`"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="last_salary"
                  :label="`${$t('0032')}`"
                  required
                  maxlength="8"
                  type="text"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="expect_salary"
                  :label="`${$t('0034')}`"
                  required
                  maxLength="8"
                  type="text"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  v-model="form.resume"
                  :rules="resumeRules"
                  show-size
                  counter
                  :label="`${$t('0036')}`"
                  type="file"
                  accept=".pdf"
                  required
                ></v-file-input>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  v-model="form.transcript"
                  :rules="transcriptRules"
                  show-size
                  counter
                  :label="`${$t('0035')}`"
                  type="file"
                  accept=".pdf"
                  required
                ></v-file-input>
              </v-col>

              <v-col cols="12 mb-0" class="text-left">
                <v-checkbox
                  v-model="checkbox"
                  :rules="checkboxRules"
                  required
                  style="
                    width: fit-content;
                    display: inline-block;
                    vertical-align: middle;
                  "
                >
                  <template v-slot:label>
                    <div>
                      {{ $t("0039") }}
                    </div>
                  </template>
                </v-checkbox>
                <span class="under-line ml-1" @click="dialog = true">
                  {{ $t("0148") }}
                </span>
              </v-col>
              <v-col cols="12" class="resume-remark">
                {{ $t("0037") }}
              </v-col>
            </v-row>
          </v-form>
          <v-btn :disabled="!valid" class="mr-4 mt-8" @click="submitForm">
            {{ $t("0038") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="triangle-right-top-1"></div>
    <div class="triangle-left-bottom-2"></div>
    <DialogPrivacyPolicy v-model="dialog" />
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import api from "@/services/api";
import DialogPrivacyPolicy from "./components/DialogPrivacyPolicy.vue";

export default {
  async mounted() {
    await this.fetchData();
    this.form.position = this.$route.query.jobID;
  },

  name: "ApplyJob",

  components: {
    DialogPrivacyPolicy,
  },
  created() {
    AOS.init();
  },
  methods: {
    async fetchData() {
      try {
        const data = { status: this.$route.params.status };
        const resultJob = await api.getCareerDropdown(data);
        if (resultJob.status == 200) {
          this.itemsPosi = resultJob.data.data;
        }

        const resultTileName = await api.getTitleNamevDropdown();
        if (resultTileName.status == 200) {
          this.itemsTitleName = resultTileName.data.data;
        }

        const resultEduLv = await api.getEduLvDropdown();
        if (resultEduLv.status == 200) {
          this.itemsEduLv = resultEduLv.data.data;
        }

        const resultEduMj = await api.getEduMjDropdown();
        if (resultEduMj.status == 200) {
          this.itemsEduMj = resultEduMj.data.data;
        }
      } catch (err) {
      } finally {
      }
    },

    async submitForm() {
      try {
        // if (this.$refs.form.validate()) {
        //   this.overlay = true;
        //   let bodyFormData = new FormData();
        //   bodyFormData.append("resume", this.form.resume);
        //   bodyFormData.append("transcript", this.form.transcript);
        //   bodyFormData.append("title_name", this.form.title_name);
        //   bodyFormData.append("name_th", this.form.name_th);
        //   bodyFormData.append("name_eng", this.form.name_eng);
        //   bodyFormData.append("email", this.form.email);
        //   bodyFormData.append("birthday", this.form.birthday);
        //   bodyFormData.append("position", this.form.position);
        //   bodyFormData.append("edu_level", this.form.edu_level);
        //   bodyFormData.append("edu_major", this.form.edu_major.idx);
        //   bodyFormData.append("other", this.form.other);
        //   bodyFormData.append("years_exp", this.form.years_exp);

        //   bodyFormData.append("last_salary", this.last_salary);
        //   bodyFormData.append("expect_salary", this.expect_salary);
        //   bodyFormData.append("id_no", this.id_no);
        //   bodyFormData.append("tel", this.tel);
        //   const applyForm = await api.postApplyJob(bodyFormData);
        //   if (applyForm.status == 200) {
        //     // console.log("applyForm.status " + applyForm.status);
        //     this.overlay = false;
        //     this.$swal({
        //       position: "center",
        //       icon: "success",
        //       title: "Success",
        //       text: "Your submission has been sent.",
        //       showConfirmButton: false,
        //       timer: 4000,
        //     });
        //     setTimeout(() => {
        //       this.$router.push({ name: "Career" });
        //     }, 4000);
        //   } else {
        //     console.log("applyForm.status not 200 else" + applyForm.status);
        //     this.overlay = false;
        //     this.$swal({
        //       position: "center",
        //       icon: "error",
        //       title: "Sorry",
        //       text: "Your submission has fail.",
        //       showConfirmButton: false,
        //       timer: 4000,
        //     });
        //     setTimeout(() => {
        //       this.$router.push({ name: "Career" });
        //     }, 4000);
        //   }
        // }
        if (this.$refs.form.validate()) {
          this.overlay = true;
          grecaptcha.ready(() => {
            grecaptcha
              .execute("6LcOwHcmAAAAALuMxEDvjQ4viKZ-aNdCghBLKZ_n", {
                action: "applyJob",
              })
              .then(async (token) => {
                const data = {
                  token: token,
                };
                const reCaptcha = await api.reCaptcha(data);
                if (reCaptcha.status == 200) {
                  this.scrollRecaptcha = reCaptcha.data.message.score;
                  if (this.scrollRecaptcha >= 0.5) {
                    let bodyFormData = new FormData();
                    bodyFormData.append("resume", this.form.resume);
                    bodyFormData.append("transcript", this.form.transcript);
                    bodyFormData.append("title_name", this.form.title_name);
                    bodyFormData.append("name_th", this.form.name_th);
                    bodyFormData.append("name_eng", this.form.name_eng);
                    bodyFormData.append("email", this.form.email);
                    bodyFormData.append("birthday", this.form.birthday);
                    bodyFormData.append("position", this.form.position);
                    bodyFormData.append("edu_level", this.form.edu_level);
                    bodyFormData.append("edu_major", this.form.edu_major.idx);
                    bodyFormData.append("other", this.form.other);
                    bodyFormData.append("years_exp", this.form.years_exp);

                    bodyFormData.append("last_salary", this.last_salary);
                    bodyFormData.append("expect_salary", this.expect_salary);
                    bodyFormData.append("id_no", this.id_no);
                    bodyFormData.append("tel", this.tel);
                    const applyForm = await api.postApplyJob(bodyFormData);
                    if (applyForm.status == 200) {
                      console.log("applyForm.status " + applyForm.status);
                      this.overlay = false;
                      this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Submit Success",
                        text: "Your submission has been sent.",
                        showConfirmButton: false,
                        timer: 4000,
                      });
                      setTimeout(() => {
                        this.$router.push({ name: "Career" });
                      }, 4000);
                    } else {
                      console.log("applyForm.status not 200 else" + applyForm.status);
                      this.overlay = false;
                      this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Sorry",
                        text: "Your submission has fail.",
                        showConfirmButton: false,
                        timer: 4000,
                      });
                      setTimeout(() => {
                        this.$router.push({ name: "Career" });
                      }, 4000);
                    }
                  } else {
                    console.log('this.scrollRecaptcha not >= 0.5 else ' + this.scrollRecaptcha);
                    this.overlay = false;
                    this.$swal({
                      position: "center",
                      icon: "error",
                      title: "Sorry",
                      text: "Your submission has fail.",
                      showConfirmButton: false,
                      timer: 4000,
                    });
                    setTimeout(() => {
                      this.$router.push({ name: "Career" });
                    }, 4000);
                  }
                } else {
                  console.log("reCaptcha not 200 else " + reCaptcha);
                  this.overlay = false;
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: "Sorry",
                    text: "Your submission has fail.",
                    showConfirmButton: false,
                    timer: 4000,
                  });
                  setTimeout(() => {
                    this.$router.push({ name: "Career" });
                  }, 4000);
                }
              });
          });
        }
      } catch (err) {
        this.overlay = false;
        this.$swal({
          icon: "error",
          title: "Sorry",
          text: err.message,
          timer: 4000,
        });
      } finally {
      }
    },

    validate() {
      this.$refs.form.validate();
    },

    save(birthDate) {
      this.$refs.menu.save(birthDate);
    },
  },

  data: () => ({
    dialog: false,
    testPass: "",
    overlay: false,
    maxYear: new Date().getFullYear() - 18,
    maxBirthdate: new Date().getFullYear() - 17 + "01" + "01",
    scrollRecaptcha: "",
    form: {
      title_name: "",
      name_th: "",
      name_eng: "",
      id_no: "",
      tel: "",
      email: "",
      birthday: "",
      position: "",
      edu_level: "",
      edu_major: "",
      other: "",
      years_exp: "",
      resume: null,
      transcript: null,
    },
    valid: true,
    titleNameRules: [(v) => !!v || "Title Name is required"],
    nameENRules: [(v) => !!v || "Name is required"],
    nameTHRules: [(v) => !!v || "Name is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phoneNumbRules: [
      (v) => !!v || "Phone Number is required",
      (v) => v.length == 10 || "please input Phone Number for 10 digit",
    ],
    id_no: "",
    tel: "",
    idNoRules: [
      (v) => !!v || "ID Card number is required",
      (v) => v.length == 13 || "please input ID Card for 13 digit",
    ],
    idNoMax: 13,
    phoneNumberMax: 10,
    menu: "",
    activePicker: null,
    birthDateRules: [(v) => !!v || "Birth day is required"],
    resumeRules: [
      (value) => !!value || "Resume is required",
      (value) =>
        !value ||
        value.size < 10485760 ||
        "PDF file size should be less than 10 MB",
    ],
    transcriptRules: [
      (value) => !!value || "Transcript is required",
      (value) =>
        !value ||
        value.size < 10485760 ||
        "PDF file size should be less than 10 MB",
    ],
    idCardRules: [(v) => !!v || "ID Card is required"],
    itemsPosi: [],
    itemsTitleName: [],
    itemsEduLv: [],
    itemsEduMj: [],
    itemsYear: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "10+"],
    levelRules: [(v) => !!v || "Education level is required"],
    majorRules: [(v) => !!v || "Major is required"],
    otherMajorRules: [(v) => !!v || "Major is required"],
    last_salary: "",
    expect_salary: "",
    min: 10000,
    max: 100000,
    range: [10000, 20000],
    checkbox: false,
    checkboxRules: [(v) => !!v || "You must agree to continue!"],
    lang: "en",
    languages: [
      { flag: "us", language: "en", title: "EN" },
      { flag: "th", language: "th", title: "TH" },
    ],
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },

    last_salary: function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (this.last_salary = result));
    },
    expect_salary: function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (this.expect_salary = result));
    },
    id_no: function (newValue) {
      const result = newValue.replace(/\D/g, "");
      this.$nextTick(() => (this.id_no = result));
    },
    tel: function (newValue) {
      const result = newValue.replace(/\D/g, "");
      this.$nextTick(() => (this.tel = result));
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: 1.875rem;
  text-align: center;
  margin-bottom: 40px;
}
p {
  text-align: center;
  margin-bottom: 30px;
  font-family: SAMSUNGSHARPSANSBOLD;
}
.apply-job {
  width: auto;
  padding: 85px 0 100px 0;
  min-height: 100vh;
}
section {
  position: relative;
}
.section-1 {
  padding: 100px 0 70px 0;
}
.title {
  color: #434343 !important;
}
.sub-title {
  font-family: SAMSUNGSHARPSANSBOLD;
  margin-top: 15px;
}
.btn-apply {
  margin-top: 20px;
}
.form .col,
.form .col-sm-6 {
  padding: 5px 12px !important;
}
.label {
  color: rgba(0, 0, 0, 0.6);
}
.salary-remark {
  position: relative;
}
.salary-remark div {
  position: absolute;
  top: 0;
  font-size: 0.725rem;
}
.salary-remark div:first-child {
  left: 0;
}
.salary-remark div:last-child {
  right: 0;
}
.resume-remark {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  margin-top: -10px;
}
@media screen and (max-width: 767px) {
  .apply-job {
    padding: 60px 0 100px 0;
  }
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  .salary-remark div {
    font-size: 0.6rem;
  }
  .form .col,
  .form .col-sm-6 {
    padding: 0px 12px !important;
  }
}
@media screen and (max-width: 280px) {
  .salary-remark div {
    font-size: 0.5rem;
  }
}
</style>
