<template>
  <div class="apply-job relative">
    <v-container>
      <v-row justify="center">
        <v-col
          md="8"
          cols="12"
          style="z-index: 2; text-align: center"
          v-if="!dataCheck.check_application_doc"
        >
          <h1 data-aos="fade-down">{{ $t("0057") }}</h1>
          <v-form
            class="form"
            ref="form"
            v-model="valid"
            lazy-validation
            data-aos="fade-up"
          >
            <v-row justify="center">
              <v-col sm="10" cols="12">
                <v-file-input
                  v-model="form.application_form"
                  :rules="allRules"
                  show-size
                  counter
                  :label="`${$t('0149')}`"
                  type="file"
                  required
                  accept=".pdf"
                ></v-file-input>
              </v-col>
              <v-col sm="10" cols="12">
                <v-file-input
                  v-model="form.privacy_policy"
                  :rules="allRules"
                  show-size
                  counter
                  :label="`${$t('0150')}`"
                  type="file"
                  required
                  accept=".pdf"
                ></v-file-input>
              </v-col>
              <v-col sm="10" cols="12">
                <v-file-input
                  v-model="form.house_registration"
                  :rules="allRules"
                  show-size
                  counter
                  :label="`${$t('0151')}`"
                  type="file"
                  required
                  accept=".pdf"
                ></v-file-input>
              </v-col>
              <v-col sm="10" cols="12">
                <v-file-input
                  v-model="form.id_card"
                  :rules="allRules"
                  show-size
                  counter
                  :label="`${$t('0152')}`"
                  type="file"
                  required
                  accept=".pdf"
                ></v-file-input>
              </v-col>
              <v-col sm="10" cols="12">
                <v-file-input
                  v-model="form.payment"
                  show-size
                  counter
                  :label="`${$t('0153')}`"
                  type="file"
                  accept=".pdf"
                  :rules="sizeRules"
                ></v-file-input>
              </v-col>
              <v-col sm="10" cols="12" v-if="dataCheck.military">
                <v-file-input
                  v-model="form.military"
                  :rules="sizeRules"
                  show-size
                  counter
                  :label="`${$t('0250')}`"
                  type="file"
                  required
                  accept=".pdf"
                ></v-file-input>
              </v-col>

              <v-col sm="10" cols="12 mb-0" style="text-align: left">
                <!-- <v-checkbox
                    v-model="checkbox"
                    :rules="checkboxRules"
                    :label="`${$t('0039')}`"
                    required
                  ></v-checkbox> -->
                <v-checkbox
                  v-model="checkbox"
                  :rules="checkboxRules"
                  required
                  style="
                    width: fit-content;
                    display: inline-block;
                    vertical-align: middle;
                  "
                >
                  <template v-slot:label>
                    <div>
                      {{ $t("0039") }}
                    </div>
                  </template>
                </v-checkbox>
                <span class="under-line ml-1" @click="dialog = true">
                  {{ $t("0148") }}
                </span>
              </v-col>
              <v-col sm="10" cols="12" class="resume-remark">
                {{ $t("0037") }}
              </v-col>
            </v-row>
          </v-form>
          <v-btn :disabled="!valid" class="mr-4 mt-5" @click="submitForm">
            {{ $t("0038") }}
          </v-btn>
        </v-col>
        <v-col
          v-else
          sm="10"
          cols="12"
          class="d-flex justify-center align-center"
          style="height: 100vh"
        >
          <v-card class="pa-10 text-center">
            <v-card-title class="text-center sharpsans-bold">
              Thank you, You are has been uploaded the documents.
            </v-card-title>

            <v-card-text>
              <v-icon size="100" color="success"> mdi-check </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="triangle-top-left-6"></div>
    <div class="triangle-bottom-left-6"></div>
    <div class="triangle-right-bottom-5"></div>
    <DialogPrivacyPolicy v-model="dialog" />
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import api from "@/services/api";
import DialogPrivacyPolicy from "./components/DialogPrivacyPolicy.vue";

export default {
  components: {
    DialogPrivacyPolicy,
  },
  async mounted() {
    this.form.request_work_idx = this.$route.params.idx;
    await this.fetchCheckCandidateDocs();
  },

  name: "ApplyJob",
  created() {
    AOS.init();
  },

  methods: {
    async fetchCheckCandidateDocs() {
      try {
        const result = await api.postCheckCandidateDocs({
          request_work_idx: this.$route.params.idx,
        });
        if (result.status == 200) {
          this.dataCheck = result.data;
        }
      } catch (err) {
        this.$swal({
          icon: "error",
          title: "Sorry",
          text: err.message,
          showConfirmButton: false,
          timer: 4000,
        });
        setTimeout(() => {
          this.$router.push({ name: "Home" });
        }, 4000);
      } finally {
      }
    },
    async submitForm() {
      try {
        if (this.$refs.form.validate()) {
          let bodyFormData = new FormData();
          bodyFormData.append("application", this.form.application_form);
          bodyFormData.append("privacy_policy", this.form.privacy_policy);
          bodyFormData.append(
            "house_registration",
            this.form.house_registration
          );
          bodyFormData.append("id_card", this.form.id_card);
          bodyFormData.append("payment_slip", this.form.payment);
          bodyFormData.append("military", this.form.military);
          bodyFormData.append("request_work_idx", this.form.request_work_idx);

          const applyForm = await api.postCandidateDocs(bodyFormData);
          if (applyForm.status == 200) {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Success",
              text: "Your documents has been sent.",
              showConfirmButton: false,
              timer: 4000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Home" });
            }, 4000);
          }
        }
      } catch (err) {
        this.$swal({
          icon: "error",
          title: "Sorry",
          text: err.message,
          showConfirmButton: false,
          timer: 4000,
        });
        setTimeout(() => {
          this.$router.push({ name: "Home" });
        }, 4000);
      } finally {
      }
    },

    validate() {
      this.$refs.form.validate();
    },
  },

  data: () => ({
    dialog: false,
    form: {
      request_work_idx: "",
      application_form: null,
      privacy_policy: null,
      house_registration: null,
      id_card: null,
      payment: null,
      military: null,
    },
    dataCheck: "",
    valid: true,
    checkboxRules: [(v) => !!v || "You must agree to continue!"],
    checkbox: false,
    allRules: [
      (v) => !!v || "The document is required",
      (value) =>
        !value ||
        value.size < 10485760 ||
        "PDF file size should be less than 10 MB",
    ],
    sizeRules: [
      (value) =>
        !value ||
        value.size < 10485760 ||
        "PDF file size should be less than 10 MB",
    ],
    lang: "en",
    languages: [
      { flag: "us", language: "en", title: "EN" },
      { flag: "th", language: "th", title: "TH" },
    ],
  }),
};
</script>
<style scoped>
h1 {
  font-size: 1.875rem;
  text-align: center;
  margin-bottom: 40px;
}
p {
  text-align: center;
  margin-bottom: 30px;
  font-family: SAMSUNGSHARPSANSBOLD;
}
.apply-job {
  width: auto;
  padding: 85px 0 100px 0;
  min-height: 100vh;
}
.title {
  color: #434343 !important;
}
.sub-title {
  font-family: SAMSUNGSHARPSANSBOLD;
  margin-top: 15px;
}
.btn-apply {
  margin-top: 20px;
}
.form .col,
.form .col-sm-6 {
  padding: 5px 12px !important;
}
.label {
  color: rgba(0, 0, 0, 0.6);
}
.salary-remark {
  position: relative;
}
.salary-remark div {
  position: absolute;
  top: 0;
  font-size: 0.725rem;
}
.salary-remark div:first-child {
  left: 0;
}
.salary-remark div:last-child {
  right: 0;
}
.resume-remark {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  margin-top: -10px;
}
@media screen and (max-width: 767px) {
  .apply-job {
    padding: 60px 0 100px 0;
  }
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  .salary-remark div {
    font-size: 0.6rem;
  }
  .form .col,
  .form .col-sm-6 {
    padding: 0px 12px !important;
  }
}
@media screen and (max-width: 280px) {
  .salary-remark div {
    font-size: 0.5rem;
  }
}
</style>
