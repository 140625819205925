import httpClient from "@/services/httpClient";
import { SYSTEM } from "@/services/constants";

// export const login = async (values) => {
//   const result = await httpClient.post(SYSTEM.LOGIN, values);
//   return result;
// };

// export const logout = async () => {
//   const result = await httpClient.post(SYSTEM.LOGOUT);
//   return result;
// };

// export const verify = async () => {
//   const result = await httpClient.get(SYSTEM.VERIFY);
//   return result;
// };

// export const getFooter = async () => {
//   const result = await httpClient.get(SYSTEM.FOOTER);
//   return result;
// };

// export const getMainMenu = async () => {
//   const result = await httpClient.get(SYSTEM.MAIN_MENU);
//   return result;
// };

export const getCareerList = async (data) => {
  const result = await httpClient.post(SYSTEM.GET_CAREER_LIST, data);
  return result;
};
export const getCareerDropdown = async (status) => {
  const result = await httpClient.post(SYSTEM.GET_CAREER_DROPDOWN, status);
  return result;
};

export const postApplyJob = async (data) => {
  const result = await httpClient.post(SYSTEM.APPLY_JOB_ON, data);
  return result;
};
export const reCaptcha = async (data) => {
  const result = await httpClient.post(SYSTEM.RECAPTCHA, data);
  return result;
};
export const postCandidateDocs = async (data) => {
  const result = await httpClient.post(SYSTEM.POST_CANDIDATE_DOCS, data);
  return result;
};
export const postCheckCandidateDocs = async (data) => {
  const result = await httpClient.post(SYSTEM.POST_CHECK_CANDIDATE_DOCS, data);
  return result;
};

export const getTitleNamevDropdown = async () => {
  const result = await httpClient.get(SYSTEM.GET_TITLE_NAME_DROPDOWN);
  return result;
};

export const getEduLvDropdown = async () => {
  const result = await httpClient.get(SYSTEM.GET_EDU_LEVEL_DROPDOWN);
  return result;
};

export const getEduMjDropdown = async () => {
  const result = await httpClient.get(SYSTEM.GET_EDU_MAJOR_DROPDOWN);
  return result;
};

export const postInfoInterest = async (data) => {
  const result = await httpClient.post(SYSTEM.POST_INFO_INTEREST, data);
  return result;
};

export const postInterest = async (data) => {
  const result = await httpClient.post(SYSTEM.POST_INTEREST, data);
  return result;
};
export const postBiddingList = async (data) => {
  const result = await httpClient.post(SYSTEM.POST_BIDDING_LIST, data);
  return result;
};
export const postRegCandidate = async (data) => {
  const result = await httpClient.post(SYSTEM.POST_REG_CANDIDATE, data);
  return result;
};

export const getCatList = async () => {
  const result = await httpClient.get(SYSTEM.GET_CAT_LIST);
  return result;
};
export const getRecruitCountView = async () => {
  const result = await httpClient.get(SYSTEM.GET_RECRUIT_COUNT_VIEW);
  return result;
};
export const getThaiSamaungCountView = async () => {
  const result = await httpClient.get(SYSTEM.GET_THAISAMSUNG_COUNT_VIEW);
  return result;
};
export const getProvince = async () => {
  const result = await httpClient.get(SYSTEM.GET_PROVINCE);
  return result;
};
export const getCalendarByYear = async (data) => {
  const result = await httpClient.post(SYSTEM.GET_CALENDAR_BY_YEAR, data);
  return result;
};
export const getCountView = async (data) => {
  const result = await httpClient.post(SYSTEM.POST_COUNT_VIEW, data);
  return result;
};
export const postInfoEvent = async (data) => {
  const result = await httpClient.post(SYSTEM.POST_INFO_EVENT, data);
  return result;
};
