<template>
      <v-row align="center" class="mt-3 relative">
        <v-item-group v-model="window" class="shrink rec-item" mandatory>
          <v-item
            v-for="(n, index) in length"
            :key="'a' + index"
            v-slot="{ active, toggle }"
          >
            <div>
              <v-btn :input-value="active" icon @click="toggle">
                <v-icon>mdi-record</v-icon>
              </v-btn>
            </div>
          </v-item>
        </v-item-group>

        <v-col>
          <v-window v-model="window" vertical>
            <v-window-item
              v-for="(i, index) in length"
              :key="'b' + index"
              class="window-height"
            >
              <v-row
                style="background-color: #2fbbf0; margin: 0"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <v-col class="slide-wrap" style="padding: 0" md="6" cols="12">
                  <v-carousel
                    cycle
                    hide-delimiter-background
                    show-arrows-on-hover
                    class="carousel-height"
                  >
                    <v-carousel-item
                      v-for="(slide, index) in i.imgSlide"
                      :key="'c' + index"
                    >
                      <v-img
                        class="img-slide-csr"
                        :src="require(`@/assets/images/csr/${slide.img}`)"
                      ></v-img>
                    </v-carousel-item>
                  </v-carousel>
                </v-col>
                <v-col class="text-wrap" md="6" cols="12" v-if="window == 0">
                  <div class="text-title">{{ $t("0080") }}</div>
                  <p>{{ $t("0081") }}</p>
                </v-col>
                <v-col class="text-wrap" md="6" cols="12" v-if="window == 1">
                  <div class="text-title">{{ $t("0082") }}</div>
                  <p>{{ $t("0083") }}</p>
                </v-col>
                <v-col class="text-wrap" md="6" cols="12" v-if="window == 2">
                  <div class="text-title">{{ $t("0084") }}</div>
                  <p>{{ $t("0085") }}</p>
                </v-col>
                <v-col class="text-wrap" md="6" cols="12" v-if="window == 3">
                  <div class="text-title">{{ $t("0086") }}</div>
                  <p>{{ $t("0087") }}</p>
                </v-col>
                <v-col class="text-wrap" md="6" cols="12" v-if="window == 4">
                  <div class="text-title">{{ $t("0088") }}</div>
                  <p>{{ $t("0089") }}</p>
                </v-col>
                <v-col class="text-wrap" md="6" cols="12" v-if="window == 5">
                  <div class="text-title">{{ $t("0090") }}</div>
                  <p>{{ $t("0091") }}</p>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
</template>
<script>
export default {
  data() {
    return {
      length: [
        {
          imgSlide: [
            { img: "csr-1 (1).jpg" },
            { img: "csr-1 (2).jpg" },
            { img: "csr-1 (3).jpg" },
            { img: "csr-1 (4).jpg" },
            { img: "csr-1 (5).jpg" },
          ],
        },
        {
          imgSlide: [
            { img: "csr-2 (1).jpg" },
            { img: "csr-2 (2).jpg" },
            { img: "csr-2 (3).jpg" },
            { img: "csr-2 (4).jpg" },
            { img: "csr-2 (5).jpg" },
          ],
        },
        {
          imgSlide: [
            { img: "csr-3 (1).jpg" },
            { img: "csr-3 (2).jpg" },
            { img: "csr-3 (3).jpg" },
            { img: "csr-3 (4).jpg" },
          ],
        },
        {
          imgSlide: [
            { img: "csr-4 (1).jpg" },
            { img: "csr-4 (2).jpg" },
            { img: "csr-4 (3).jpg" },
            { img: "csr-4 (4).jpg" },
          ],
        },
        {
          imgSlide: [
            { img: "csr-5 (1).jpg" },
            { img: "csr-5 (2).jpg" },
            { img: "csr-5 (3).jpg" },
            { img: "csr-5 (4).jpg" },
          ],
        },
      ],
      window: 0,
    };
  },
};
</script>

<style>
.rec-item {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
}
.window-height, .carousel-height {
  max-height: 50vh;
}
.img-slide-csr {
  height: 100%;
}
@media screen and (max-width: 991px) {
  .window-height {
    max-height: unset;
  }
  .carousel-height {
    height: 400px !important;
    max-height: unset;
  }
  .img-slide-csr {
    height: 80%;
  }
}
@media screen and (max-width: 650px) {
  .carousel-height {
    height: 250px !important;
  }
}
@media screen and (max-width: 460px) {
  .carousel-height {
    height: 210px !important;
  }
  .img-slide-csr {
    height: 60%;
  }
}
</style>
