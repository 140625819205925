//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE =
  "An error has occurred. The photo was unable to upload.";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";


// export const apiUrl = "https://107.101.26.134:3011";
export const apiUrl = "https://thaisamsung.co.th";


export const imageUrl = `${apiUrl}/bidding/api/public/show_content`;

export const SYSTEM = {

  GET_CAREER_LIST: "api/get_position",
  GET_CAREER_DROPDOWN: "api/career",
  APPLY_JOB_ON: "api/req_work",
  RECAPTCHA: "api/reCaptcha",
  GET_TITLE_NAME_DROPDOWN: "api/get_titlename",
  GET_EDU_LEVEL_DROPDOWN: "api/get_edu_level",
  GET_EDU_MAJOR_DROPDOWN: "api/get_edu_major",
  POST_CANDIDATE_DOCS: "api/application_doc",
  POST_CHECK_CANDIDATE_DOCS: "api/check_application_doc",
  POST_INFO_INTEREST: "api/info",
  POST_INTEREST: "api/interest",
  POST_REG_CANDIDATE : "bidding/api/public/reg_candidate",
  POST_BIDDING_LIST : "bidding/api/public/get_front_event_list",
  GET_CAT_LIST : "bidding/api/public/get_category_list",
  POST_COUNT_VIEW : "bidding/api/public/count_view",
  POST_INFO_EVENT : "bidding/api/public/info_event",
  GET_PROVINCE : "bidding/api/public/provinces",
  GET_CALENDAR_BY_YEAR : "bidding/api/public/list_calendar_by_year",
  GET_RECRUIT_COUNT_VIEW : "api/recruitment/count_viewer",
  GET_THAISAMSUNG_COUNT_VIEW : "api/thaisamsung/count_viewer",

};



