<template>
  <section class="section-1">
    <div class="intro-wrap-1" style="z-index: 2">
      <div class="head-wrap">
        <h1
          style="color: #000"
          class="text-header text-shadow"
          data-aos="fade-left"
          data-aos-delay="400"
        >
          {{ $t("0069") }}
        </h1>
        <h2
          style="color: #000"
          class="text-header text-shadow"
          data-aos="fade-left"
          data-aos-delay="600"
        >
          {{ $t("0070") }}
        </h2>
        <p
          class="mt-4 text-shadow"
          style="color: #000"
          data-aos="fade-left"
          data-aos-delay="800"
        >
          {{ $t("0071") }}
        </p>
        <v-btn
          dark
          elevation="6"
          color="#31bff2"
          data-aos="fade-left"
          data-aos-delay="1000"
          @click="$router.push({ name: 'AboutUs' }).catch(() => {})"
          >about us</v-btn
        >
      </div>
      <v-container
        class="mt-6"
        align="center"
        justify="center"
        data-aos="fade-left"
        data-aos-delay="1200"
      >
        <v-row style="margin: -24px" align="center" justify="center">
          <v-col md="4" sm="10" cols="12"
            ><a href="#section2"
              ><div class="relative intro-card">
                <v-img
                  class="img-what-we"
                  :src="require(`@/assets/images/what-we-do.png`)"
                ></v-img>
                <strong class="text-sub-header">What We Do</strong>
              </div></a
            ></v-col
          >
          <v-col md="4" sm="10" cols="12"
            ><a href="#section5"
              ><div class="relative intro-card">
                <v-img
                  class="img-what-we"
                  :src="require(`@/assets/images/what-we-care.png`)"
                ></v-img>
                <strong class="text-sub-header">What We Care</strong>
              </div></a
            ></v-col
          >
          <v-col md="4" sm="10" cols="12"
            ><a href="#section4"
              ><div class="relative intro-card">
                <v-img
                  class="img-what-we"
                  :src="require(`@/assets/images/what-we-ways.png`)"
                ></v-img>
                <strong class="text-sub-header">What We Value</strong>
              </div></a
            ></v-col
          >
          <!-- <v-col md="4" sm="10" cols="12"
            ><a href="#section2"
              ><div class="section-card do-card">
                <strong class="text-sub-header">What We Do</strong>
              </div></a
            ></v-col
          > -->
          <!-- <v-col md="4" sm="10" cols="12"
            ><a href="#section5"
              ><div class="section-card care-card">
                <strong class="text-sub-header">What We Care</strong>
              </div></a
            ></v-col
          > -->

          <!-- <v-col md="4" sm="10" cols="12"
            ><a href="#section4"
              ><div class="section-card ways-card">
                <strong class="text-sub-header">What We Ways</strong>
              </div></a
            ></v-col
          > -->
        </v-row>
      </v-container>
    </div>
    <div class="triangle-top-left-6"></div>
    <div class="triangle-bottom-left-6"></div>
    <div class="triangle-right-bottom-5"></div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.section-1 {
  background-image: url("/src/assets/images/cover-2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
@supports (-webkit-touch-callout: none) {
  .section-1 {
    background-image: url("/src/assets/images/cover-2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
}
.intro-wrap-1 {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  max-width: 60%;
}
.head-wrap {
  width: 100%;
  max-width: 80%;
}
.section-card {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
  transition: 0.2s;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important;
}
.do-card {
  background-image: url("/src/assets/images/what-we-do.png");
}
.care-card {
  background-image: url("/src/assets/images/what-we-care.png");
}
.ways-card {
  background-image: url("/src/assets/images/what-we-ways.png");
}
@supports (-webkit-touch-callout: none) {
  .section-card {
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-position: center center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: relative;
    transition: 0.2s;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
      0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important;
  }
  .section-1 {
    background-image: url("/src/assets/images/cover-2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
}
.section-card:hover .text-sub-header {
  background-color: hsla(0, 0%, 27%, 0.741);
  padding: 25px 0px 25px 12px;
  top: 50%;
}
.text-sub-header {
  background-color: #454545bd;
  width: 100%;
  padding: 0px 12px 0px 12px;
  font-size: 1.375rem;
  position: absolute;
  color: #fff;
  top: 12%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  letter-spacing: 2px;
  transition: 0.7s;
}
.intro-card {
  width: 100%;
  height: 200px;
}
.img-what-we {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1680px) {
  .intro-wrap-1 {
    max-width: 70%;
  }
}
@media screen and (max-width: 1264px) {
  .head-wrap {
    max-width: 100%;
  }
}
@media screen and (max-width: 991px) {
  @supports (-webkit-touch-callout: none) {
    .section-1 {
      background-image: url("/src/assets/images/cover-2-mb.png");
      height: auto !important;
      min-height: 110vh;
      background-attachment: initial;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
    }
  }

  .section-1 {
    background-image: url("/src/assets/images/cover-2-mb.png");
    height: auto !important;
    min-height: 110vh;
  }
  .intro-wrap-1 {
    max-width: 80%;
    position: relative;
    z-index: 1;
    top: unset !important;
    left: unset !important;
    transform: unset !important;

    margin: 0 auto;
    padding: 120px 0 100px 0;
  }
}
@media screen and (max-width: 575px) {
  .intro-wrap-1 {
    padding: 75px 0 60px 0;
  }
}
</style>
