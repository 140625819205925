<template>
  <section id="section2" class="section-2">
    <div class="intro-wrap-2" style="z-index: 2">
      <div class="text-header-section" data-aos="fade-up" data-aos-delay="5">
        What We Do
      </div>
      <div class="liner mt-2" data-aos="fade-up" data-aos-delay="20">
        {{ $t("0072") }}
      </div>
      <v-carousel cycle height="400" hide-delimiter-background class="mt-4">
        <v-carousel-item v-for="(slide, index) in slideProduct" :key="index">
          <h2 class="text-title-2" v-if="index == 0">{{ $t("0073") }}</h2>
          <h2 class="text-title-2" v-if="index == 1">{{ $t("0074") }}</h2>
          <h2 class="text-title-2" v-if="index == 2">{{ $t("0075") }}</h2>
          <h2 class="text-title-2" v-if="index == 3">{{ $t("0076") }}</h2>
          <h2 class="text-title-2" v-if="index == 4">{{ $t("0077") }}</h2>
          <h2 class="text-title-2" v-if="index == 5">{{ $t("0078") }}</h2>
          <v-img
            class="img-product"
            :src="require(`@/assets/images/product/${slide.img}`)"
          ></v-img>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="triangle-right-top-1"></div>
    <div class="triangle-left-bottom-2"></div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      slideProduct: [
        { img: "dish-washer.png" },
        { img: "ref.png" },
        { img: "washing.png" },
        { img: "oven.png" },
        { img: "air-clean.png" },
        { img: "air.png" },
      ],
    };
  },
};
</script>

<style>
.intro-wrap-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  max-width: 60%;
}
.text-title-2 {
  font-size: 2rem;
}
.img-product {
  height: auto;
  margin: 0 auto;
  max-width: 480px;
}
@media screen and (max-width: 1680px) {
  .intro-wrap-2 {
    max-width: 70%;
  }
}
@media screen and (max-width: 991px) {
  .section-2 {
    height: auto !important;
    min-height: 100vh;
  }
  .intro-wrap-2 {
    max-width: 80%;
    position: relative;
    z-index: 1;
    top: unset !important;
    left: unset !important;
    transform: unset !important;

    margin: 0 auto;
    padding: 70px 0 70px 0;
  }
}
</style>
