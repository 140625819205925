<template>
  <v-app>
    <!-- <p>{{ $t('message') }}</p> -->
    <Header @changeLocale="changeLocaleHead" v-if="$route.path !== '/error-page'" />
    <Navigation @changeLocale="changeLocaleNav" v-if="$route.path !== '/' && $route.path !== '/error-page'" />
    <Content :locale="locale" />
    <Footer v-if="$route.path" />
    <Loading :value="showLoading" />
  </v-app>
</template>

<script>
import Header from "@/components/core/header.vue";
import Navigation from "@/components/core/navigation.vue";
import Content from "@/components/core/content.vue";
import Footer from "@/components/core/footer.vue";
import Loading from "@/components/Loading";

export default {
  watch: {
    locale(val){
      console.log(val);
      localStorage.setItem('languages', val)
    }
  },
  methods: {
    changeLocaleHead(val) {
      this.locale = val
    },
    changeLocaleNav(val) {
      this.locale = val
    },
  },
  data() {
    return {
      showLoading: false,
      locale: null,
    };
  },

  components: {
    Header,
    Content,
    Loading,
    Footer,
    Navigation,
  },
  name: "App",
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: SAMSUNGSHARPSANSREG;
  color: #434343;
  /* font-size: 1rem; */
}

.v-messages__message {
  color: #ff5252 !important;
  font-size: 0.675rem;
}
.v-text-field input,
.v-select__selection--comma {
  font-size: 1.125rem;
}
.v-date-picker-title__year,
.v-date-picker-title__date > div {
  color: #fff;
}
.theme--light.v-label {
  color: #434343c5;
}
.hide-arrow input[type="number"] {
  -moz-appearance: textfield;
}
.hide-arrow input::-webkit-outer-spin-button,
.hide-arrow input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
@font-face {
  font-family: SAMSUNGSHARPSANSREG;
  src: url("assets/font/SAMSUNGSHARPSANS-REGULAR_1.OTF") format("opentype");
}
@font-face {
  font-family: SAMSUNGSHARPSANSBOLD;
  src: url("assets/font/SAMSUNGSHARPSANS-BOLD_1.OTF") format("opentype");
}
.sharpsansbold {
  font-family: SAMSUNGSHARPSANSBOLD;
}
.under-line {
  border-bottom: 1px solid #1598c9;
  color: #1598c9;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer
}
.text-link {
  text-decoration: none;
  color: #1598c9 !important;
}
.proc-tag {
  color: #fff;
  padding: 2px 8px;
  text-transform: capitalize;
  border-radius: 2px;
}
.proc-img-content {
  width: 500px;
  cursor: pointer;
}
.proc-img-preview {
  width: 700px;
  max-width: 100vw;
}
.proc-title {
  font-size: 1.375rem;
  margin-top: 5px;
  margin-bottom: 15px;
  transition: 0.3s;
  width: fit-content;
}
.proc-title:hover {
  color: #1598c9;
  transition: 0.3s;
}
.v-text-field--outlined fieldset {
  border-width: 0 !important;
}
.day-header, .month-title, .year-title {
  font-family: SAMSUNGSHARPSANSBOLD !important;
}
</style>
