<template>
  <div class="bus-contact">
         <v-container>
        <v-row justify="center">
          <v-col md="7" cols="11" style="z-index:2;">
            <h1 class="title-h1" data-aos="fade-down">{{ $t("0146") }}</h1>
            <strong class="strong">{{ $t("0041") }}</strong>

            <div data-aos="fade-up" data-aos-delay="300" class="content-box mt-8">
              {{ $t("0042") }}
            </div>

            <div data-aos="fade-up" data-aos-delay="700" class="content-box">
              {{ $t("0043") }}
            </div>

            <div data-aos="fade-up" data-aos-delay="900" class="content-box">
              {{ $t("0044") }}
            </div>

            <div data-aos="fade-up" data-aos-delay="950" class="content-box">
              {{ $t("0045") }}
            </div>
            <div data-aos="fade-up" data-aos-delay="950" class="content-box">
              {{ $t("0046") }}
            </div>

          </v-col>
        </v-row>
      </v-container>
      <div class="triangle-top-left-6"></div>
    <div class="triangle-bottom-left-6"></div>
    <div class="triangle-right-bottom-5"></div>
      </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {

  data() {
    return {
    };
  },
  name: "AboutUs",
  created() {
    AOS.init();
  },
};
</script>
<style>
.bus-contact {
  width: auto;
  padding: 100px 0 120px 0;
}
.content-box {
  margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
  .bus-contact  {
    padding: 80px 0 120px 0;
  }
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }
  .content-box {
    margin-bottom: 15px;
  }
}
</style>
